import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Spin, Button, Divider } from 'antd'
import { ColumnsType, TableProps } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { initialize } from 'redux-form'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import CustomTable from '../../components/CustomTable'
import SmsUnitPricesForm from './components/SmsUnitPricesForm'

// utils
import { PERMISSION, ROW_GUTTER_X_DEFAULT, FORM, STRINGS, CREATE_BUTTON_ID, DEFAULT_DATE_INIT_FORMAT } from '../../utils/enums'
import Permissions, { withPermissions } from '../../utils/Permissions'
import { deleteReq, patchReq, postReq } from '../../utils/request'
import { formFieldID, normalizeDirectionKeys, setOrder } from '../../utils/helper'
import { decodePrice, encodePrice, formatDateTimeByLocale, formatDateTimeRangeByLocale, formatPriceByLocale } from '../../utils/intl'

// reducers
import { getSmsUnitPrices, ISmsUnitPricesPayload } from '../../reducers/smsUnitPrices/smsUnitPricesActions'

// assets
import PlusIcon from '../../assets/icons/plus-icon.svg?react'

// types
import { IBreadcrumbs, ISmsUnitPricesForm } from '../../types/interfaces'
import { RootState } from '../../reducers'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'
import useBackUrl from '../../hooks/useBackUrl'

// schema
import { smsUnitPricesDetailPageQueryParams } from '../../schemas/queryParams'

type TableDataItem = NonNullable<ISmsUnitPricesPayload['data']>['unitPricesPerCountry'][0]

const SmsUnitPricesDetailPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const [visibleForm, setVisibleForm] = useState<boolean>(false)
	const [isRemoving, setIsRemoving] = useState<boolean>(false)

	const { countryCode: countryCodeUrlParam } = useParams<{ countryCode: string }>()
	const countryCode = (countryCodeUrlParam as string).toUpperCase()

	const [query, setQuery] = useQueryParams(smsUnitPricesDetailPageQueryParams, {
		order: 'validFrom:DESC',
		limit: 25,
		page: 1
	})

	// undefined - represents new record
	const [selectedSmsUnitPrice, setSelectedSmsUnitPrice] = useState<{ id: string; disabled?: boolean } | undefined>(undefined)

	const smsUnitPrices = useSelector((state: RootState) => state.smsUnitPrices.smsUnitPrices)
	const config = useSelector((state: RootState) => state.config.config)
	const currencies = config.data?.systemCurrencies
	const rolloutCountries = config.data?.rolloutCountries

	const country = rolloutCountries?.find((item) => item.code === countryCode)
	const countryName = country?.name
	const currencySymbol = currencies?.find((currency) => currency.code === country?.currencyCode)?.symbol

	const [backUrl] = useBackUrl(t('paths:sms-credits'))

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam SMS kreditov'),
				link: backUrl
			},
			{
				name: t('loc:Detail krajiny'),
				titleName: countryName || ''
			}
		]
	}

	const fetchData = useCallback(
		() => dispatch(getSmsUnitPrices({ countryCode, limit: query.limit, page: query.page, order: query.order })),
		[dispatch, countryCode, query.limit, query.page, query.order]
	)

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const changeFormVisibility = (show?: boolean, smsUnitPrice?: TableDataItem) => {
		if (!show) {
			setVisibleForm(false)
			dispatch(initialize(FORM.SMS_UNIT_PRICES_FORM, { countryCode }))
			return
		}

		if (smsUnitPrice) {
			const decodedPrice = decodePrice(smsUnitPrice.amount)
			if (decodedPrice === null) {
				throw new Error('The price was not decoded, but is required to show correct form data')
			}

			const initData: ISmsUnitPricesForm = {
				amount: decodedPrice,
				validFrom: smsUnitPrice.validFrom,
				countryCode: smsUnitPrice.country.code
			}

			dispatch(initialize(FORM.SMS_UNIT_PRICES_FORM, initData))
		}

		setSelectedSmsUnitPrice(smsUnitPrice ? { id: smsUnitPrice.id, disabled: dayjs(smsUnitPrice.validFrom).isSameOrBefore(dayjs()) } : undefined)
		setVisibleForm(true)
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === smsUnitPrices?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const handleSubmit = async (formData: ISmsUnitPricesForm) => {
		const reqBody = {
			amount: encodePrice(formData.amount),
			validFrom: formData.validFrom
		}

		try {
			if (selectedSmsUnitPrice?.id) {
				await patchReq('/api/b2b/admin/enums/sms-unit-prices/{smsUnitPriceID}', { params: { path: { smsUnitPriceID: selectedSmsUnitPrice.id } }, reqBody })
			} else {
				await postReq('/api/b2b/admin/enums/sms-unit-prices/', { params: {}, reqBody: { ...reqBody, countryCode: formData.countryCode } })
			}
			fetchData()
			changeFormVisibility()
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		}
	}

	const handleDelete = async () => {
		if (!selectedSmsUnitPrice?.id || isRemoving) {
			return
		}
		setIsRemoving(true)
		try {
			await deleteReq('/api/b2b/admin/enums/sms-unit-prices/{smsUnitPriceID}', { params: { path: { smsUnitPriceID: selectedSmsUnitPrice.id } }, reqBody: {} })
			fetchData()
			changeFormVisibility()
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		}
		setIsRemoving(false)
	}

	const formClass = cx({
		'w-2/3 xl:w-1/2': visibleForm
	})

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:Krajina'),
			dataIndex: 'country',
			key: 'country',
			width: '20%',
			ellipsis: true,
			render: () => {
				const name = country?.name || country?.code
				return (
					<div className={'flex items-center gap-2'}>
						{country?.flag && <img src={country?.flag} alt={name} width={24} />}
						<span className={'truncate inline-block'}>{name}</span>
					</div>
				)
			}
		},
		{
			title: t('loc:Jednotková cena SMS'),
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			width: '40%',
			ellipsis: true,
			render: (_value, record) => {
				const value = record.amount
				const { currencyCode } = record.country
				const currency = currencies?.find((item) => item.code === currencyCode)
				let result = formatPriceByLocale(value, currency?.code)
				if (record.actualValid) {
					result = t('loc:aktuálne platná {{ price }}', { price: result })
				}
				return result
			}
		},
		{
			title: t('loc:Platnosť'),
			dataIndex: 'validFrom',
			key: 'validFrom',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			width: '40%',
			className: 'table-col-with-sorter-right',
			sortOrder: setOrder(query.order, 'validFrom'),
			align: 'right',
			render: (_value, record) => {
				const valueFrom = record.validFrom
				const valueTo = record.validTo
				let result = ''
				if (valueTo) {
					result = formatDateTimeRangeByLocale(valueFrom, valueTo, { timeStyle: null })
				} else {
					result = t('loc:od {{ timeFrom }}', { timeFrom: formatDateTimeByLocale(valueFrom, { timeStyle: null }) })
				}
				return result
			}
		}
	]

	const isEmptyCountry = isEmpty(smsUnitPrices.data?.unitPricesPerCountry)

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<Row gutter={ROW_GUTTER_X_DEFAULT}>
				<Col span={24}>
					<div className={cx('content-body', { 'pb-0': !visibleForm })}>
						<Spin spinning={smsUnitPrices?.isLoading || isRemoving}>
							<div className={'pt-0 flex gap-4 justify-between items-center'}>
								<h3 className={'text-base whitespace-nowrap'}>{t('loc:Ceny SMS správ')}</h3>
								<Permissions
									allowed={[PERMISSION.SMS_UNIT_PRICE_EDIT]}
									render={(hasPermission, { openForbiddenModal }) => (
										<Button
											onClick={() => {
												if (hasPermission) {
													dispatch(
														initialize(FORM.SMS_UNIT_PRICES_FORM, {
															countryCode: countryCode?.toLocaleUpperCase(),
															validFrom: isEmptyCountry ? dayjs().startOf('month').format(DEFAULT_DATE_INIT_FORMAT) : undefined
														})
													)
													changeFormVisibility(true)
												} else {
													openForbiddenModal()
												}
											}}
											type='primary'
											htmlType='button'
											className={'noti-btn'}
											icon={<PlusIcon />}
											id={formFieldID(FORM.SMS_UNIT_PRICES_FORM, CREATE_BUTTON_ID)}
										>
											{STRINGS(t).addRecord(t('loc:cenu'))}
										</Button>
									)}
								/>
							</div>
							<div className={'w-full flex'}>
								<div className={cx(formClass, { 'mb-4': visibleForm })}>
									<CustomTable<TableDataItem>
										className={'table-fixed'}
										wrapperClassName={'table-with-pagination-at-the-bottom'}
										columns={columns}
										onChange={onChangeTable}
										dataSource={smsUnitPrices.data?.unitPricesPerCountry}
										rowClassName={'clickable-row'}
										twoToneRows
										rowKey={'id'}
										onRow={(record) => ({
											onClick: () => changeFormVisibility(true, record)
										})}
										useCustomPagination
										pagination={{
											pageSize: smsUnitPrices.data?.pagination?.limit,
											total: smsUnitPrices?.data?.pagination?.totalCount,
											current: smsUnitPrices?.data?.pagination?.page,
											disabled: smsUnitPrices?.isLoading,
											onChange: onChangePagination
										}}
									/>
								</div>
								{visibleForm ? (
									<div className={'w-6/12 flex justify-around items-start'}>
										<Divider className={'h-full mx-6 xl:mx-9'} type={'vertical'} />
										<SmsUnitPricesForm
											currencySymbol={currencySymbol}
											smsUnitPriceID={selectedSmsUnitPrice?.id}
											disabledForm={selectedSmsUnitPrice?.disabled}
											config={config}
											changeFormVisibility={changeFormVisibility}
											onSubmit={handleSubmit}
											onDelete={handleDelete}
											isEmptyCountry={isEmptyCountry}
										/>
									</div>
								) : undefined}
							</div>
						</Spin>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO, PERMISSION.SMS_UNIT_PRICE_EDIT]))(SmsUnitPricesDetailPage)
