import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Row, Spin } from 'antd'
import { change, getFormInitialValues, getFormValues, initialize, isPristine, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { useNavigate } from 'react-router-dom'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import DynamicNotificationForm from './components/DynamicNotificationsForm'
import DynamicNotificationsSettingsForm from './components/DynamicNotificationsSettingsForm'
import ConfirmModal from '../../atoms/ConfirmModal'

// types
import { IBreadcrumbs, IDynamicNotificationsForm, IDynamicNotificationsSettingsForm, PostUrls, RequestPayload } from '../../types/interfaces'

// utils
import { withPermissions } from '../../utils/Permissions'
import {
	ALL_LANGUAGES,
	DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE,
	DYNAMIC_NOTIFICATIONS_URL_TYPE,
	CONFIRM_SUBMIT_BUTTON_ID,
	FORM,
	LANGUAGE,
	PERMISSION,
	SUBMIT_BUTTON_ID
} from '../../utils/enums'
import { postReq } from '../../utils/request'
import { formFieldID, getStringifiedLanguages } from '../../utils/helper'
import { isEnumValue } from '../../utils/intl'

// reducers
import { RootState } from '../../reducers'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

// assets
import CreateIcon from '../../assets/icons/plus-icon.svg?react'
import BellIcon from '../../assets/icons/bell-icon.svg?react'
import LanguagesIcon from '../../assets/icons/languages-icon.svg?react'

type DynamicNotificationPostRequestBody = RequestPayload<PostUrls['/api/b2b/admin/dynamic-notifications/']>

type ReqBodyInit = {
	contentLocalizations: DynamicNotificationPostRequestBody['titleLocalizations']
	titleLocalizations: DynamicNotificationPostRequestBody['contentLocalizations']
	localizedNotificationData: DynamicNotificationPostRequestBody['localizedNotificationData']
}

const ALL_LANGUAGES_ARR = Object.values(LANGUAGE)

const DEFAULT_SETTINGS = {
	selectedLanguages: [],
	allLanguages: ALL_LANGUAGES.ALL_LANGUAGES
}

const CreateDynamicNotificationPage = () => {
	const [t] = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const initialFormValuesSettings = useSelector((state: RootState) => getFormInitialValues(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS)(state)) as
		| IDynamicNotificationsSettingsForm
		| undefined
	const formValuesNotifications = useSelector((state: RootState) => getFormValues(FORM.DYNAMIC_NOTIFICATIONS)(state)) as IDynamicNotificationsForm | undefined
	const isNotificationsFormPristine = useSelector(isPristine(FORM.DYNAMIC_NOTIFICATIONS))
	const isSettingsFormPristine = useSelector(isPristine(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS))

	const [isNotificationSettingsModalOpen, setIsNotificationSettingsModalOpen] = useState(false)
	const [confirmModalData, setConfirmModalData] = useState<{ handler: Function } | null>(null)
	const [submitting, setSubmitting] = useState<boolean>(false)

	const [backUrl] = useBackUrl(t('paths:notifications'))

	// View
	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam upozornení'),
				link: backUrl
			},
			{
				name: t('loc:Vytvoriť upozornenie')
			}
		]
	}

	useEffect(() => {
		// open settings modal on first init
		setIsNotificationSettingsModalOpen(true)

		// initialize default values for settings form
		dispatch(
			initialize(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS, {
				selectedLanguages: DEFAULT_SETTINGS.selectedLanguages,
				allLanguages: DEFAULT_SETTINGS.allLanguages
			})
		)

		// initialize default values for notification form
		dispatch(
			initialize(FORM.DYNAMIC_NOTIFICATIONS, {
				allLanguages: DEFAULT_SETTINGS.allLanguages,
				selectedLanguages: ALL_LANGUAGES_ARR,
				localizations: ALL_LANGUAGES_ARR.reduce(
					(acc, cv) => {
						return {
							...acc,
							[cv]: {
								notificationType: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.OTHER,
								title: '',
								content: ''
							}
						}
					},
					{} as { [key: string]: IDynamicNotificationsForm['localizations'][keyof IDynamicNotificationsForm['localizations']] }
				)
			})
		)
	}, [dispatch])

	const handleSubmitDynamicNotificationsSettings = (values: IDynamicNotificationsSettingsForm) => {
		let selectedLanguages: IDynamicNotificationsForm['selectedLanguages'] = []

		if (values.allLanguages === ALL_LANGUAGES.ALL_LANGUAGES) {
			selectedLanguages = ALL_LANGUAGES_ARR
		} else if (values.allLanguages === ALL_LANGUAGES.SELECTED_LANGUAGES) {
			selectedLanguages = values.selectedLanguages
		}

		// change notification form
		dispatch(change(FORM.DYNAMIC_NOTIFICATIONS, 'selectedLanguages', selectedLanguages))
		dispatch(change(FORM.DYNAMIC_NOTIFICATIONS, 'allLanguages', values.allLanguages))
		// reinitialize settings form on submit
		dispatch(initialize(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS, values))
		// close settings modal
		setIsNotificationSettingsModalOpen(false)
	}

	const handleCancelDynamicNotificationsSettings = () => {
		if (!isSettingsFormPristine && initialFormValuesSettings) {
			// in case the form was changed, but not submitted, set the values to intial values
			dispatch(initialize(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS, initialFormValuesSettings))
		}
		setIsNotificationSettingsModalOpen(false)
	}

	const submitData = async (values: IDynamicNotificationsForm) => {
		setSubmitting(true)
		setConfirmModalData(null)

		try {
			const reqBody = Object.entries(values.localizations).reduce<ReqBodyInit>(
				(acc, [language, value]) => {
					if (!isEnumValue(language, LANGUAGE)) {
						throw new Error(`${language}. Unsupported language for creating dynamic notification.`)
					}

					if (!values.selectedLanguages?.includes(language)) {
						return acc
					}

					let data = {
						...acc,
						titleLocalizations: [...acc.titleLocalizations, { language, value: value.title }],
						contentLocalizations: [...acc.contentLocalizations, { language, value: value.content }]
					}

					if (value.externalLink) {
						data = {
							...data,
							localizedNotificationData: [
								...(acc.localizedNotificationData || []),
								{
									language,
									type: DYNAMIC_NOTIFICATIONS_LOCALIZED_DATA_TYPE.OTHER,
									payload: {
										externalLink: {
											url: value.externalLink,
											type: DYNAMIC_NOTIFICATIONS_URL_TYPE.GENERAL
										}
									}
								}
							]
						}
					}

					return data
				},
				{ contentLocalizations: [], titleLocalizations: [], localizedNotificationData: null }
			)

			const { data } = await postReq('/api/b2b/admin/dynamic-notifications/', { params: {}, reqBody })

			if (data.dynamicNotification?.id) {
				navigate(`${t('paths:notifications')}/${data.dynamicNotification?.id}`)
			} else {
				navigate(t('paths:notifications'))
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setSubmitting(false)
		}
	}

	const handleSubmitDynamicNotifications = (values: IDynamicNotificationsForm) => {
		setConfirmModalData({ handler: () => submitData(values) })
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>

			<div className='content-body medium'>
				<Spin spinning={submitting}>
					<div className={'flex items-center w-full gap-2'}>
						<BellIcon className={'flex-shrink-0'} />
						<h3 className={'mb-0'}>
							{formValuesNotifications?.allLanguages === ALL_LANGUAGES.ALL_LANGUAGES
								? t('loc:Vytvoriť nové upozornenie pre všetky jazyky')
								: t('loc:Vytvoriť nové upozornenie pre vybrané jazyky')}
						</h3>
						<Button
							type={'primary'}
							icon={<LanguagesIcon />}
							size={'middle'}
							className={'noti-btn ml-auto'}
							htmlType={'button'}
							onClick={() => setIsNotificationSettingsModalOpen(true)}
						>
							{t('loc:Vybrať jazyky')}
						</Button>
					</div>
					<Divider className={'mb-3 mt-3'} />
					<DynamicNotificationForm onSubmit={handleSubmitDynamicNotifications} />
					<div className={'content-footer'}>
						<Row justify='center'>
							<Button
								id={formFieldID(FORM.DYNAMIC_NOTIFICATIONS, SUBMIT_BUTTON_ID)}
								type={'primary'}
								icon={<CreateIcon />}
								size={'middle'}
								className={'noti-btn m-regular w-full md:w-auto md:min-w-50 xl:min-w-60'}
								htmlType={'submit'}
								onClick={() => {
									dispatch(submit(FORM.DYNAMIC_NOTIFICATIONS))
								}}
								disabled={submitting || isNotificationsFormPristine}
								loading={submitting}
							>
								{t('loc:Odoslať upozornenie')}
							</Button>
						</Row>
					</div>
				</Spin>
			</div>
			{}
			<ConfirmModal
				open={isNotificationSettingsModalOpen}
				closable={false}
				maskClosable
				title={t('loc:Vytvoriť nové upozornenie')}
				onCancel={handleCancelDynamicNotificationsSettings}
				onOk={() => dispatch(submit(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS))}
				disabled={submitting}
				okText={t('loc:Pokračovať')}
				okButtonProps={{ id: formFieldID(FORM.DYNAMIC_NOTIFICATIONS_SETTINGS, SUBMIT_BUTTON_ID) }}
			>
				<p className={'mb-6 text-sm'}>{t('loc:Zvoľte, či chcete vytvoriť upozornenie pre všetky jazyky alebo iba pre vybrané jazyky používateľov.')}</p>
				<DynamicNotificationsSettingsForm onSubmit={handleSubmitDynamicNotificationsSettings} />
			</ConfirmModal>
			<ConfirmModal
				open={!!confirmModalData}
				okText={t('loc:Odoslať upozornenie')}
				onOk={() => confirmModalData?.handler()}
				closeIcon={false}
				title={t('loc:Odoslať upozornenie?')}
				onCancel={() => setConfirmModalData(null)}
				destroyOnClose
				okButtonProps={{
					id: formFieldID(FORM.DYNAMIC_NOTIFICATIONS, CONFIRM_SUBMIT_BUTTON_ID)
				}}
			>
				<p>
					<strong>
						{t('loc:Naozaj si želáte odoslať upozornenie pre jazyky {{ languages }}?', {
							languages: getStringifiedLanguages(formValuesNotifications?.selectedLanguages)
						})}
					</strong>
				</p>
				<p>
					{t(
						'loc:Upozornenia sa posielajú hromadne na všetkých používateľov zvolených jazykov, preto je potrebné rátať s ich postupným odosielaním v časovom úseku medzi {{ timeRange }}.',
						{ timeRange: '9:00 - 17:00' }
					)}
				</p>
			</ConfirmModal>
		</>
	)
}

export default compose(withPermissions([PERMISSION.DYNAMIC_NOTIFICATION_CREATE]))(CreateDynamicNotificationPage)
