import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import i18next from 'i18next'
import { Row } from 'antd'
import { compose } from 'redux'
import { initialize } from 'redux-form'

// components
import CreateSalonPage from './CreateSalonPage'
import EditSalonPage from './EditSalonPage'
import Breadcrumbs from '../../components/Breadcrumbs'
import TabsComponent from '../../components/TabsComponent'
import SalonHistoryPage from './SalonHistoryPage'

// utils
import { getPrefixCountryCode } from '../../utils/helper'
import { checkPermissions, withPermissions } from '../../utils/Permissions'
import { initSalonFormData } from './components/salonUtils'

// enums
import { FORM, NEW_SALON_ID, PERMISSION, SALON_DETAIL_FORM_SECTION, SALON_TABS_KEYS, STRINGS } from '../../utils/enums'

// reducers
import { getSalonLanguages } from '../../reducers/languages/languagesActions'
import { selectSalon } from '../../reducers/selectedSalon/selectedSalonActions'

// types
import { IBreadcrumbs, ISalonForm, SalonPageProps, SalonSubPageProps } from '../../types/interfaces'
import { RootState } from '../../reducers'

// hooks
import useBackUrl from '../../hooks/useBackUrl'
import { useChangeOpeningHoursFormFields } from '../../components/OpeningHours/OpeningHoursUtils'

const getTabBreadcrumbName = (selectedTabKey: SALON_TABS_KEYS) => {
	switch (selectedTabKey) {
		case SALON_TABS_KEYS.SALON_HISTORY:
			return i18next.t('loc:História salónu')
		case SALON_TABS_KEYS.SALON_DETAIL:
		default:
			return i18next.t('loc:Detail salónu')
	}
}

const SalonPage: FC<SalonSubPageProps & { tabKey: SALON_TABS_KEYS }> = (props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { salonID, parentPath } = props

	const tabKey = props.tabKey || SALON_TABS_KEYS.SALON_DETAIL

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const config = useSelector((state: RootState) => state.config.config)
	const { optionsPhonePrefixesAll } = config
	const salon = useSelector((state: RootState) => state.selectedSalon.selectedSalon)

	const isDeletedSalon = !!salon?.data?.deletedAt && salon?.data?.deletedAt !== null

	const formValues: Partial<ISalonForm> | undefined = useSelector((state: RootState) => state.form?.[FORM.SALON]?.values)

	const phonePrefixCountryCode = getPrefixCountryCode(optionsPhonePrefixesAll)

	const isNotinoUser = useMemo(() => checkPermissions(authUser.data?.uniqPermissions, [PERMISSION.NOTINO]), [authUser])

	const isNewSalon = salonID === NEW_SALON_ID

	const [backUrl] = useBackUrl(t('paths:salons'))

	const openingHours = formValues?.[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]?.openingHours
	const sameOpenHoursOverWeekFormValue = !!formValues?.[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]?.sameOpenHoursOverWeek
	const openOverWeekendFormValue = !!formValues?.[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]?.openOverWeekend

	useChangeOpeningHoursFormFields(
		FORM.SALON,
		openingHours,
		sameOpenHoursOverWeekFormValue,
		openOverWeekendFormValue,
		`${[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]}.openingHours`
	)

	const fetchSalonData = useCallback(
		async (id: string | null, initializeForm = true) => {
			const { data } = await dispatch(selectSalon(id))

			if (initializeForm) {
				dispatch(initialize(FORM.SALON, initSalonFormData(data, phonePrefixCountryCode)))
			}
		},
		[dispatch, phonePrefixCountryCode]
	)

	// fetch data on init
	useEffect(() => {
		if (!isNewSalon) {
			fetchSalonData(salonID, tabKey === SALON_TABS_KEYS.SALON_DETAIL)
		}
	}, [salonID, isNewSalon, fetchSalonData, tabKey])

	useEffect(() => {
		dispatch(getSalonLanguages())
	}, [dispatch])

	const commonProps: SalonPageProps = {
		isNotinoUser,
		phonePrefixCountryCode,
		authUser,
		configLoading: config.isLoading
	}

	const salonDetail = (
		<EditSalonPage {...commonProps} fetchSalonData={fetchSalonData} salonID={salonID} salon={salon} isDeletedSalon={isDeletedSalon} backUrl={backUrl} parentPath={parentPath} />
	)

	const getBreadcrumbs = () => {
		let breadcrumbDetailItem
		if (isNewSalon) {
			breadcrumbDetailItem = {
				name: STRINGS(t).createRecord(t('loc:salón'))
			}
		} else {
			breadcrumbDetailItem = {
				name: getTabBreadcrumbName(tabKey),
				titleName: `${salon.data?.name ?? ''} | ID: ${salon.data?.id}`
			}
		}

		// View
		const breadcrumbs: IBreadcrumbs = {
			items: isNotinoUser
				? [
						{
							name: t('loc:Zoznam salónov'),
							link: backUrl
						},
						breadcrumbDetailItem
					]
				: [breadcrumbDetailItem]
		}

		return breadcrumbs
	}

	const onTabChange = (newTabKey: string) => {
		if (newTabKey === SALON_TABS_KEYS.SALON_DETAIL) {
			navigate(t('paths:salons/{{salonID}}', { salonID }))
		}
		if (newTabKey === SALON_TABS_KEYS.SALON_HISTORY) {
			navigate(t('paths:salons/{{salonID}}/history', { salonID }))
		}
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={getBreadcrumbs()} />
			</Row>
			{isNewSalon ? (
				<CreateSalonPage {...commonProps} />
			) : (
				<>
					{isNotinoUser && !isDeletedSalon ? (
						<TabsComponent
							className={'box-tab'}
							activeKey={tabKey}
							defaultActiveKey={tabKey}
							onChange={onTabChange}
							destroyInactiveTabPane
							items={[
								{
									key: SALON_TABS_KEYS.SALON_DETAIL,
									label: t('loc:Detail salónu'),
									children: salonDetail
								},
								{
									key: SALON_TABS_KEYS.SALON_HISTORY,
									label: t('loc:História salónu'),
									children: <SalonHistoryPage salonID={salonID} />
								}
							]}
						/>
					) : (
						salonDetail
					)}
				</>
			)}
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO, PERMISSION.PARTNER]))(SalonPage)
