import React from 'react'
import { ItemType } from 'antd/es/menu/interface'
import { useTranslation } from 'react-i18next'
import { Dropdown, Row } from 'antd'

// utils
import { RESERVATION_STATE } from '../../../utils/enums'
import { formatDateTimeByLocale } from '../../../utils/intl'
import { getCloseButton, ReservationDetailProps } from '../reservationPopoverUtils'

// assets
import EditIcon from '../../../assets/icons/edit-icon.svg?react'
import DotsIcon from '../../../assets/icons/more-info-horizontal-icon.svg?react'

type HeaderProps = Pick<ReservationDetailProps, 'calendarEvent' | 'headerButtons'> & {
	headerIcon: React.ReactNode
	headerState: string
	moreMenuItems: ItemType[]
	onClose: () => void
	isSidebar?: boolean
}

const HeaderContent = (props: HeaderProps) => {
	const { headerButtons, calendarEvent, onClose, headerIcon, headerState, moreMenuItems, isSidebar = false } = props
	const { reservationData, awaitingApprovalUntilAt } = calendarEvent
	const { onEditEvent, onMoreMenuItemsDropdownOpenChange } = headerButtons || {}

	const [t] = useTranslation()

	return (
		<>
			<Row className={'state-wrapper gap-2 flex-nowrap items-center'}>
				<div>{headerIcon}</div>
				<div className={'text-sm leading-4'}>
					<div>{headerState}</div>
					{reservationData?.state === RESERVATION_STATE.PENDING && awaitingApprovalUntilAt && (
						<div className='xs-regular text-notino-grayDark mt-1'>
							{t('loc:Ak rezervácia nebude potvrdená do {{ time }}, bude automaticky zrušená.', {
								time: formatDateTimeByLocale(awaitingApprovalUntilAt)
							})}
						</div>
					)}
				</div>
			</Row>
			<Row className={'buttons gap-4 shrink-0'}>
				{!isSidebar && (
					<button
						className={'nc-popover-header-button relative'}
						type={'button'}
						onClick={() => {
							if (onEditEvent) {
								onEditEvent(calendarEvent)
							}
						}}
					>
						<span className={'absolute w-0 h-0 overflow-hidden opacity-0'}>{t('loc:Upraviť rezerváciu')}</span>
						<EditIcon className={'small-icon'} />
					</button>
				)}
				{moreMenuItems.length > 0 && (
					<Dropdown
						menu={{
							className: 'shadow-md max-w-xs min-w-48  mt-1 p-2 flex flex-col gap-2',
							items: moreMenuItems
						}}
						placement='bottomRight'
						trigger={['click']}
						onOpenChange={onMoreMenuItemsDropdownOpenChange}
					>
						<button className={'nc-popover-header-button relative'} type={'button'} onClick={(e) => e.preventDefault()}>
							<span className={'absolute w-0 h-0 overflow-hidden opacity-0'}>{t('loc:Zobraziť viac možností')}</span>
							<DotsIcon style={{ transform: 'rotate(90deg)' }} />
						</button>
					</Dropdown>
				)}
				{getCloseButton(onClose)}
			</Row>
		</>
	)
}

export default HeaderContent
