import React, { useEffect, useState, useMemo } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Row, Spin } from 'antd'
import { ColumnsType, TableProps } from 'antd/es/table'
import { useNavigate } from 'react-router'
import { initialize } from 'redux-form'
import cx from 'classnames'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import CustomTable from '../../components/CustomTable'
import SmsUnitPricesFilter from './components/SmsUnitPricesFilter'
import SmsTimeStatsAdmin from './components/SmsTimeStatsAdmin'

// assets
import PlusIcon from '../../assets/icons/plus-icon.svg?react'
import ChevronLeftIcon from '../../assets/icons/chevron-left-16.svg?react'

// utils
import { FORM, PERMISSION, RECHARGE_SMS_CREDIT_BUTTON_ID, SMS_UNIT_PRICES_TABLE_ID } from '../../utils/enums'
import Permissions, { withPermissions } from '../../utils/Permissions'
import { getLinkWithEncodedBackUrl, normalizeDirectionKeys, setOrder, sortData, transformToLowerCaseWithoutAccent } from '../../utils/helper'
import { formatDateTimeByLocale, formatPriceByLocale, LocalizedDateTime } from '../../utils/intl'

// types
import { IBreadcrumbs, ISmsUnitPricesFilter } from '../../types/interfaces'
import { RootState } from '../../reducers'

// redux
import { getSmsUnitPricesActual, ISmsUnitPricesActualPayload } from '../../reducers/smsUnitPrices/smsUnitPricesActions'

type TableDataItem = NonNullable<ISmsUnitPricesActualPayload['data']>[0] & { key: string; currencyCode?: string }

const SmsCreditAdminPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const config = useSelector((state: RootState) => state.config.config)
	const rolloutCountries = config.data?.rolloutCountries
	const currencies = config.data?.systemCurrencies
	const smsUnitPricesActual = useSelector((state: RootState) => state.smsUnitPrices.smsUnitPricesActual)

	const [query, setQuery] = useState<{ search?: string; order: string }>({
		search: '',
		order: 'country:ASC'
	})

	useEffect(() => {
		dispatch(getSmsUnitPricesActual())
	}, [dispatch])

	useEffect(() => {
		dispatch(initialize(FORM.SMS_UNIT_PRICES_FILTER, { search: query.search }))
	}, [query.search, dispatch])

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:SMS kredity')
			}
		]
	}

	const tableData = useMemo(() => {
		if (!smsUnitPricesActual.data) {
			return []
		}
		const source = query.search
			? smsUnitPricesActual.data.filter((smsUnitPrice) => {
					const countryName = transformToLowerCaseWithoutAccent(smsUnitPrice.country.name)
					const searchedValue = transformToLowerCaseWithoutAccent(query.search)
					return countryName.includes(searchedValue)
				})
			: smsUnitPricesActual.data

		// transform to table data
		return source?.map((item) => {
			const country = rolloutCountries?.find((c) => c.code === item.country.code)
			const currency = currencies?.find((c) => c.code === country?.currencyCode)
			return {
				...item,
				currencyCode: currency?.code,
				key: item.country.code
			}
		})
	}, [query.search, smsUnitPricesActual, currencies, rolloutCountries])

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const columns: ColumnsType<TableDataItem> = useMemo(() => {
		return [
			{
				title: <span id={'sortby-country'}>{t('loc:Krajina')}</span>,
				dataIndex: 'country',
				key: 'country',
				width: '25%',
				sortOrder: setOrder(query.order, 'country'),
				sorter: {
					compare: (a, b) => {
						const aValue = a?.country?.name
						const bValue = b?.country?.name
						return sortData(aValue, bValue)
					}
				},
				sortDirections: ['ascend', 'descend', 'ascend'],
				render: (_value, record) => {
					const { country } = record
					const name = country.name || country.code
					return (
						<div className={'flex items-center gap-2'}>
							{country.flag && <img src={country.flag} alt={name} width={24} />}
							<span className={'truncate inline-block'}>{name}</span>
						</div>
					)
				}
			},
			{
				title: t('loc:Aktuálna cena SMS'),
				dataIndex: 'actual',
				key: 'amount',
				ellipsis: true,
				align: 'right',
				width: '25%',
				render: (_value, record) => {
					const value = record.actual

					if (!value) {
						return '-'
					}

					return formatPriceByLocale(value.amount, record.currencyCode)
				}
			},
			{
				title: <div style={{ marginLeft: '20%' }}>{t('loc:Platná od')}</div>,
				dataIndex: 'actual',
				key: 'validFrom',
				ellipsis: true,
				sorter: false,
				width: '25%',
				render: (_value, record) => {
					return <div style={{ marginLeft: '20%' }}>{<LocalizedDateTime date={record.actual?.validFrom} timeStyle={null} ellipsis />}</div>
				}
			},
			{
				title: t('loc:Plánovaná cena SMS'),
				dataIndex: 'next',
				key: 'validFromNext',
				ellipsis: true,
				sorter: false,
				width: '30%',
				render: (_value, record) => {
					const value = record.next
					return value
						? `${formatPriceByLocale(value.amount, record.currencyCode)} ${t('loc:od {{ timeFrom }}', {
								timeFrom: formatDateTimeByLocale(value.validFrom, { timeStyle: null })
							})}`
						: '-'
				}
			},
			{
				key: 'action',
				width: 30,
				render: () => {
					return (
						<div className={'flex items-center jusitfy-center'}>
							<ChevronLeftIcon style={{ transform: 'rotate(180deg)' }} />
						</div>
					)
				}
			}
		]
	}, [query.order, t])

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<div className='content-body transparent-background'>
				<div className={'w-full flex justify-end'}>
					<Permissions
						allowed={[PERMISSION.NOTINO]}
						render={(hasPermission, { openForbiddenModal }) => (
							<Button
								id={RECHARGE_SMS_CREDIT_BUTTON_ID}
								onClick={() => {
									if (hasPermission) {
										navigate(`${t('paths:sms-credits')}/${t('paths:recharge')}`)
									} else {
										openForbiddenModal()
									}
								}}
								type='primary'
								htmlType='button'
								className={'noti-btn'}
								icon={<PlusIcon />}
							>
								{t('loc:Dobiť kredity salónom')}
							</Button>
						)}
					/>
				</div>

				<Permissions allowed={[PERMISSION.NOTINO]}>
					<SmsTimeStatsAdmin config={config} />
				</Permissions>
				<h3 className={'text-2xl whitespace-nowrap mb-4'}>{t('loc:Ceny SMS správ')}</h3>

				<div className='content-body mt-0'>
					<Spin spinning={smsUnitPricesActual?.isLoading}>
						<SmsUnitPricesFilter
							onSubmit={(values: ISmsUnitPricesFilter) => {
								setQuery({ ...query, search: values.search })
							}}
						/>
						<div className={'w-full flex'}>
							<CustomTable<TableDataItem>
								id={SMS_UNIT_PRICES_TABLE_ID}
								className={cx('table-fixed', SMS_UNIT_PRICES_TABLE_ID)}
								columns={columns}
								onChange={onChangeTable}
								dataSource={tableData}
								rowClassName={'clickable-row'}
								twoToneRows
								rowKey={(record) => record.country.code}
								pagination={false}
								onRow={(record) => {
									return {
										className: !record.actual && !record.next ? 'noti-table-row-warning' : undefined,
										onClick: () => {
											navigate(getLinkWithEncodedBackUrl(t('paths:sms-credits/{{countryCode}}', { countryCode: record.country.code.toLocaleLowerCase() })))
										}
									}
								}}
							/>
						</div>
					</Spin>
				</div>
			</div>
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO, PERMISSION.SMS_UNIT_PRICE_EDIT, PERMISSION.WALLET_TRANSACTION_CREATE]))(SmsCreditAdminPage)
