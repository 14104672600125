import React from 'react'
import { chain, filter, forEach, get, includes, isEmpty, isFinite, isNaN, isNil, isNumber, isString, lowerCase, orderBy, replace, round, size, some, split } from 'lodash'
import { Modal, ModalFuncProps, notification, Tag, Result, Button } from 'antd'
import slugify from 'slugify'
import { SubmissionError, submit } from 'redux-form'
import i18next from 'i18next'
import dayjs, { Dayjs } from 'dayjs'
import { ArgsProps } from 'antd/es/notification/interface'
import UAParser from 'ua-parser-js'
import cx from 'classnames'
import { ResultStatusType } from 'antd/es/result'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

// helpers
import { Trans } from 'react-i18next'
import {
	BROWSER_TYPE,
	BROWSERS,
	DATE_TIME_PARSER_DATE_FORMAT,
	DATE_TIME_PARSER_FORMAT,
	DAY,
	DEFAULT_DATE_TIME_OPTIONS,
	DEFAULT_LANGUAGE,
	DEFAULT_PHONE_PREFIX,
	FORM,
	UPLOAD_IN_PROGRESS_PROP,
	MIN_SUPPORTED_BROWSER_VERSION,
	MONDAY_TO_FRIDAY,
	MSG_TYPE,
	PERMISSION,
	QUERY_LIMIT,
	RESERVATION_PAYMENT_METHOD,
	RESERVATION_SOURCE_TYPE,
	RESERVATION_STATE,
	FILE_FILTER_DATA_TYPE,
	RS_NOTIFICATION_CUSTOMER,
	RS_NOTIFICATION_EMPLOYEE,
	LOCALES,
	SALON_FILTER_STATES,
	SALON_CREATE_TYPE,
	CHANGELOG_PLATFORM,
	CHANGELOG_STATUS,
	CYPRESS_CLASS_NAMES,
	CALENDAR_EVENT_TYPE,
	ERROR_MSG_CODE,
	SHORTCUT_DAYS_TRANSLATIONS,
	EXCLUDED_SMS_NOTIFICATIONS,
	RS_NOTIFICATION_CHANNEL,
	CALENDAR_DISABLED_NOTIFICATION_TYPE
} from './enums'
// eslint-disable-next-line import/no-cycle
import {
	IAuthUserPayload,
	IDateTimeFilterOption,
	IEmployeePayload,
	IErrorMessage,
	ISelectOptionItem,
	IStructuredAddress,
	FileUploadData,
	FileUploadParam,
	NameLocalizationsItem,
	RsNotificationType,
	UploadFieldValueType,
	RequestResponse,
	GetUrls,
	CalendarEvent,
	CheckboxGroupOption,
	DisabledNotificationsArray
} from '../types/interfaces'
import { phoneRegEx } from './regex'
import Navigator from './navigation'
import { formatDateTimeByLocale, formatDateTimeRangeByLocale, getLocale, isEnumValue } from './intl'
import { formatObjToQuery } from '../hooks/useQueryParamsZod'

// types
import { ISalonsActivePageURLQueryParams } from '../types/schemaTypes'

// Assets
import ClockIcon from '../assets/icons/clock-icon.svg?react'
import NotRealizedIcon from '../assets/icons/alert-circle-icon.svg?react'
import CheckSuccessIcon from '../assets/icons/approved-icon.svg?react'
import CreditCardIcon from '../assets/icons/credit-card-icon.svg?react'
import NotifCloseIcon from '../assets/icons/notification-close-icon.svg?react'
import WalletIcon from '../assets/icons/wallet.svg?react'
import DollarIcon from '../assets/icons/dollar-icon.svg?react'
import CrossedIcon from '../assets/icons/close-circle-icon.svg?react'
import ChevronDown from '../assets/icons/chevron-down.svg?react'

export const decodeBackDataQuery = (base64?: string | null) => {
	let decoded = null
	try {
		if (base64) {
			const decodedString = decodeURIComponent(atob(base64))
			decoded = JSON.parse(decodedString)
		}
	} catch (e) {
		decoded = null
	}
	return decoded
}

export const getLinkWithEncodedBackUrl = (link: string) => {
	if (!window.location.search) {
		return link
	}
	const backUrl = btoa(`${window.location.pathname}${window.location.search}`)
	return `${link}?backUrl=${backUrl}`
}

const translateMessageType = (msgType: MSG_TYPE) => {
	switch (msgType) {
		case MSG_TYPE.ERROR:
			return i18next.t('loc:Chyba')
		case MSG_TYPE.WARNING:
			return i18next.t('loc:Upozornenie')
		case MSG_TYPE.SUCCESS:
			return i18next.t('loc:Úspešné')
		case MSG_TYPE.INFO:
			return i18next.t('loc:Info')
		default:
			return ''
	}
}

export const getMimeTypeName = (mimeTypes?: string[], fileType?: FILE_FILTER_DATA_TYPE) => {
	// mapped for those values: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
	if (mimeTypes?.length) {
		const names: { mimeType: string; name: string }[] = []
		mimeTypes?.forEach((mimeType) => {
			switch (mimeType) {
				case 'image/bmp':
					names.push({ mimeType, name: '.bmp' })
					break
				case 'text/csv':
					names.push({ mimeType, name: '.csv' })
					break
				case 'application/msword':
					names.push({ mimeType, name: '.msword' })
					break
				case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
					names.push({ mimeType, name: '.xml' })
					break
				case 'image/vnd.microsoft.icon':
					names.push({ mimeType, name: '.icon' })
					break
				case 'image/jpeg':
					names.push({ mimeType, name: '.jpg, .jpeg' })
					break
				case 'application/json':
					names.push({ mimeType, name: '.json' })
					break
				case 'font/otf':
					names.push({ mimeType, name: '.otf' })
					break
				case 'image/png':
					names.push({ mimeType, name: '.png' })
					break
				case 'application/pdf':
					names.push({ mimeType, name: '.pdf' })
					break
				case 'application/rtf':
					names.push({ mimeType, name: '.rtf' })
					break
				case 'image/tiff':
					names.push({ mimeType, name: '.tiff' })
					break
				case 'text/plain':
					names.push({ mimeType, name: '.txt' })
					break
				case 'image/webp':
					names.push({ mimeType, name: '.webp' })
					break
				case 'application/vnd.ms-excel':
					names.push({ mimeType, name: '.ms-excel' })
					break
				case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
					names.push({ mimeType, name: '.sheet' })
					break
				default:
					names.push({ mimeType: 'application/pdf', name: '.pdf' })
					break
			}
		})

		const formattedNames: string[] = []
		const formattedMimeTypes: string[] = []

		names.forEach((name) => {
			formattedNames.push(name.name)
			formattedMimeTypes.push(name.mimeType)
		})

		return {
			fileType,
			names,
			formattedNames: formattedNames.join(', '),
			formattedMimeTypes: formattedMimeTypes.join(',')
		}
	}
	return null
}

export const translateDayName = (day: DAY | typeof MONDAY_TO_FRIDAY, shortName?: boolean) => {
	switch (day) {
		case DAY.MONDAY:
			return shortName ? i18next.t('loc:Po') : i18next.t('loc:Pondelok')
		case DAY.TUESDAY:
			return shortName ? i18next.t('loc:Ut') : i18next.t('loc:Utorok')
		case DAY.WEDNESDAY:
			return shortName ? i18next.t('loc:St') : i18next.t('loc:Streda')
		case DAY.THURSDAY:
			return shortName ? i18next.t('loc:Štv') : i18next.t('loc:Štvrtok')
		case DAY.FRIDAY:
			return shortName ? i18next.t('loc:Pia') : i18next.t('loc:Piatok')
		case DAY.SATURDAY:
			return shortName ? i18next.t('loc:So') : i18next.t('loc:Sobota')
		case DAY.SUNDAY:
			return shortName ? i18next.t('loc:Ne') : i18next.t('loc:Nedeľa')
		case MONDAY_TO_FRIDAY:
			return shortName ? i18next.t('loc:Po - Pia') : i18next.t('loc:Pondelok - Piatok')
		default:
			return ''
	}
}

export const translateReservationSourceType = (sourceType: RESERVATION_SOURCE_TYPE) => {
	switch (sourceType) {
		case RESERVATION_SOURCE_TYPE.ONLINE:
			return {
				text: i18next.t('loc:Klient'),
				googleAnalyticsOptionName: 'by_client'
			}
		case RESERVATION_SOURCE_TYPE.OFFLINE:
			return {
				text: i18next.t('loc:Salón'),
				googleAnalyticsOptionName: 'by_salon'
			}
		default:
			return {
				text: '-',
				googleAnalyticsOptionName: ''
			}
	}
}

export const translateReservationState = (state?: RESERVATION_STATE) => {
	switch (state) {
		case RESERVATION_STATE.NOT_REALIZED:
			return {
				text: i18next.t('loc:Nezrealizovaná'),
				icon: <NotRealizedIcon />,
				googleAnalyticsOptionName: 'not_realized'
			}
		case RESERVATION_STATE.PENDING:
			return {
				text: i18next.t('loc:Čakajúca'),
				icon: <ClockIcon color={'#FF9500'} />,
				googleAnalyticsOptionName: 'pending'
			}
		case RESERVATION_STATE.APPROVED:
			return {
				text: i18next.t('loc:Potvrdená'),
				icon: <CheckSuccessIcon color={'#008700'} />,
				googleAnalyticsOptionName: 'confirmed'
			}
		case RESERVATION_STATE.REALIZED:
			return {
				text: i18next.t('loc:Zaplatená'),
				icon: <DollarIcon color={'#008700'} />,
				googleAnalyticsOptionName: 'paid'
			}

		case RESERVATION_STATE.DECLINED:
			return {
				text: i18next.t('loc:Odmietnutá'),
				icon: <CrossedIcon />,
				googleAnalyticsOptionName: 'rejected'
			}
		case RESERVATION_STATE.CANCEL_BY_SALON:
			return {
				text: i18next.t('loc:Zrušená salónom'),
				icon: <CrossedIcon className={'text-danger'} />,
				googleAnalyticsOptionName: 'canceled_by_salon'
			}
		case RESERVATION_STATE.CANCEL_BY_SYSTEM:
			return {
				text: i18next.t('loc:Zrušená automaticky'),
				icon: <CrossedIcon className={'text-danger'} />,
				googleAnalyticsOptionName: 'canceled_by_system'
			}
		case RESERVATION_STATE.CANCEL_BY_CUSTOMER:
			return {
				text: i18next.t('loc:Zrušená zákazníkom'),
				icon: <CrossedIcon />,
				googleAnalyticsOptionName: 'canceled_by_client'
			}
		default:
			return {
				text: '-',
				icon: undefined,
				googleAnalyticsOptionName: ''
			}
	}
}
export const translateReservationPaymentMethod = (paymentMethod?: RESERVATION_PAYMENT_METHOD, className?: string) => {
	switch (paymentMethod) {
		case RESERVATION_PAYMENT_METHOD.CARD:
			return {
				text: i18next.t('loc:Platba kartou'),
				icon: <CreditCardIcon className={className} />,
				googleAnalyticsOptionName: 'card'
			}
		case RESERVATION_PAYMENT_METHOD.CASH:
			return {
				text: i18next.t('loc:Platba v hotovosti'),
				icon: <WalletIcon className={className} />,
				googleAnalyticsOptionName: 'cash'
			}
		case RESERVATION_PAYMENT_METHOD.OTHER:
			return {
				text: i18next.t('loc:Iné spôsoby platby'),
				icon: <DollarIcon className={className} />,
				googleAnalyticsOptionName: 'other'
			}
		default:
			return {
				text: '',
				icon: undefined,
				googleAnalyticsOptionName: ''
			}
	}
}

export const createSlug = (value: string, separator = '-', lower = true) => {
	if (value) {
		return slugify(value, {
			replacement: separator,
			lower
		})
	}
	return ''
}

// Number validators
export const validationNumberMin = (min: number) => (value: any) => isFinite(value) && value < min && i18next.t('loc:Zadajte minimálne {{min}}', { min })

// String validators
export const validationString = (maxLength: number) => (value: string) => get(value, 'length') > maxLength && i18next.t('loc:Max. počet znakov je {{max}}', { max: maxLength })

// Other validators
export const validationRequired = (value: string) => !value && i18next.t('loc:Toto pole je povinné')

export const validationRequiredNumber = (value: any) => (isNil(value) || isNaN(value)) && i18next.t('loc:Toto pole je povinné')

export const validationPhone = (value: string) => value && !phoneRegEx.test(value) && i18next.t('loc:Telefónne číslo nie je platné')

export const normalizeDirectionKeys = (direction: 'ascend' | 'descend' | null | undefined) => (direction === 'descend' ? 'DESC' : 'ASC')
const normalizeASCDESCKeys = (direction: string) => (direction.toUpperCase() === 'DESC' ? 'descend' : 'ascend')

export const setOrder = (order: string | null | undefined, colName: string) => {
	const [name, direction] = split(order || '', ':')
	let result
	if (name === colName) {
		result = normalizeASCDESCKeys(direction)
	}
	return result as 'descend' | 'ascend' | undefined
}

const dotNotate = (obj: any, target?: any, prefix?: any, pathSeparator = '.') => {
	// eslint-disable-next-line
	target = target || {}
	// eslint-disable-next-line no-param-reassign
	prefix = prefix || ''
	// eslint-disable-next-line
	Object.keys(obj).forEach((key) => {
		if (!isNaN(Number(key))) {
			const newKey = `[${key}]`
			const oldValue = obj[key]
			// eslint-disable-next-line
			delete obj.key
			// eslint-disable-next-line
			obj[newKey] = oldValue
			// eslint-disable-next-line
			if (typeof prefix === 'string' || prefix instanceof String) {
				// eslint-disable-next-line
				if (prefix.charAt(prefix.length - 1) === '.') prefix = prefix.substring(0, prefix.length - 1)
			}
		}
	})
	// eslint-disable-next-line
	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] === 'object') {
			dotNotate(obj[key], target, `${prefix + key}${pathSeparator}`, pathSeparator)
		} else {
			// eslint-disable-next-line
			return (target[prefix + key] = obj[key])
		}
	})

	return target
}

export const formFieldID = (form?: FORM | string, name?: string) => {
	let id
	if (form && name) {
		// NOTE: element can't be queried if id contains dots
		const fieldSelector = chain(name)
			.filter((char) => char !== ']')
			.map((char) => (char === '[' || char === '.' ? '-' : char))
			.value()
			.join('')
		id = `${form}-${fieldSelector}`
	}
	return id
}

const scrollToFirstError = (errors: any, form: FORM | string) => {
	const getDotNotation = dotNotate(errors, null, `${form}-`, '.')
	const els: any = []
	forEach(Object.keys(getDotNotation), (errName) => {
		const el = document.getElementById(errName)
		if (el && el.getBoundingClientRect) {
			els.push({
				id: errName,
				value: el.getBoundingClientRect().top
			})
		}
	})
	const sortedErrors: any = orderBy(els, ['value'], ['asc'])
	if (!isEmpty(sortedErrors)) {
		const el = document.getElementById(get(sortedErrors, '[0].id') as any)
		if (el?.scrollIntoView) {
			el.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			})
		}
	}
}

export const getPrefixCountryCode = (options: ISelectOptionItem[], fallback: string = DEFAULT_PHONE_PREFIX) => {
	const locale = split(lowerCase(i18next.language), '-')
	const language = locale[1] || locale[0]
	let prefix = fallback.toUpperCase()

	some(options, (item) => {
		const optionCountryCode = typeof item.key === 'string' ? item.key : ''
		if (!includes(language, lowerCase(optionCountryCode))) return false
		prefix = optionCountryCode
		return true
	})

	return prefix
}

export function setIntervalImmediately(func: Function, interval: number) {
	func()
	return setInterval(func, interval)
}

/**
 * @see https://medium.com/@almestaadmicadiab/how-to-parse-google-maps-address-components-geocoder-response-774d1f3375d
 */
export const parseAddressComponents = (addressComponents: any[] = []): IStructuredAddress => {
	const address: IStructuredAddress = {
		streetNumber: null,
		zip: null,
		street: null,
		city: null,
		country: null,
		houseNumber: null
	}

	if (!isEmpty(addressComponents)) {
		const addressProperties = {
			streetNumber: ['street_number'],
			houseNumber: ['premise'],
			zip: ['postal_code'],
			street: ['street_address', 'route'],
			city: ['locality', 'sublocality', 'political', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4'],
			country: ['country']
		}

		addressComponents.forEach((component: any) => {
			Object.keys(addressProperties).forEach((shouldBe) => {
				if (addressProperties[shouldBe as keyof IStructuredAddress].indexOf(component.types[0]) !== -1) {
					if (shouldBe === 'country') {
						address[shouldBe] = component.short_name
					} else {
						address[shouldBe as keyof IStructuredAddress] = component.long_name
					}
				}
			})
		})
	}

	return address
}

const fromStringToFloat = (string: string | number | null | undefined): number | null => {
	let result
	if (string && isString(string)) {
		result = parseFloat(replace(string, ',', '.').replace(' ', ''))
	} else if (string) {
		result = Number(string)
	} else {
		result = null
	}

	return result
}

/**
 * Returns null - e.g. input was cleared
 *
 * Returns NaN - e.g. input value is "asdf"
 */
export const transformNumberFieldValue = (rawValue: number | string | undefined | null, min?: number, max?: number, precision?: number, notNullValue?: boolean) => {
	let result = null
	const value = typeof rawValue === 'string' ? fromStringToFloat(rawValue) : rawValue
	if (!value && notNullValue) {
		result = min
	}
	if (isNumber(value) && isFinite(value)) {
		if (isNumber(min) && value < min) {
			result = min
		} else if (isNumber(max) && value > max) {
			result = max
		} else if (isNumber(min) && isNumber(max) && value >= min && value <= max) {
			result = value
		}
	}

	if (!Number.isNaN(value) && isFinite(result) && isNumber(precision)) {
		result = round(result as number, precision)
	}

	return result
}

/**
 * source: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param bytes number of bites in binary
 * @param decimals number of decimal places of formatted value
 * @returns human readable format. for example 10485760 bytes converts to 10MB
 */
const formatBytes = (bytes: number, decimals = 0) => {
	if (!+bytes) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`
}

export const getMaxSizeNotifMessage = (maxFileSize: number, fileName: string) => {
	return {
		type: MSG_TYPE.ERROR,
		message: (
			<Trans
				defaults={i18next.t('loc:Súbor <strong>{{fileName}}</strong> je príliš veľký (max. {{size}})')}
				components={{ strong: <strong /> }}
				values={{ fileName, size: formatBytes(maxFileSize) }}
			/>
		)
	}
}

export const getUploadLimitsMessage = (accept: string | string[], maxFileSize?: number, additionalMessage?: string) => {
	const allowedFormats = getMimeTypeName(typeof accept === 'string' ? accept.split(',') : accept)?.formattedNames
	let message = i18next.t('loc:Povolené formáty sú {{allowedFormats}}', { allowedFormats })

	if (maxFileSize !== undefined) {
		message = i18next.t('loc:Povolené formáty sú {{allowedFormats}} (max. {{size}})', { allowedFormats, size: formatBytes(maxFileSize) })
	}

	if (additionalMessage) {
		return `${message}. ${additionalMessage}`
	}
	return message
}

export const getServiceRange = (from: number | undefined | null, to: number | undefined | null, unit = '') => {
	if (isNil(from) && isNil(to)) {
		return null
	}

	if (isNil(to)) {
		return `${from || ''} ${unit}`
	}

	if (from === to) {
		return `${from} ${unit}`
	}

	return `${from || ''} - ${to || ''} ${unit}`
}

export const checkFiltersSizeWithoutSearch = (formValues: any) => size(filter(formValues, (value, key) => !isNil(value) && !isEmpty(value) && key !== 'search'))

export const checkFiltersSize = (formValues: any) => size(filter(formValues, (value) => !isNil(value) && !isEmpty(value)))

/**
 * add default language to the first position
 * or
 * move default language to the first position
 */
export const normalizeNameLocalizations = (nameLocalizations: NameLocalizationsItem[], languages: string[] = Object.keys(LOCALES), defaultLanguage: string = DEFAULT_LANGUAGE) => {
	return languages
		.sort((a, b) => {
			if (a === defaultLanguage) {
				return -1
			}
			return b === defaultLanguage ? 1 : 0
		})
		.map((language) => {
			const value = nameLocalizations.find((localization) => localization.language === language)
			return { language, value: value?.value || null }
		})
}

export const showErrorNotification = (errors: any, dispatch: any, submitError: any, props: any, customMessage?: ArgsProps) => {
	if (errors && props.form) {
		scrollToFirstError(errors, props.form)
		const errorKeys = Object.keys(errors)

		// Error invoked during image uploading has custom notification
		if (errorKeys.length === 1 && errorKeys[0] === UPLOAD_IN_PROGRESS_PROP) {
			return undefined
		}

		const errorsText = errorKeys.length > 1 ? i18next.t('loc:Vo formulári sa nachádzajú chyby!') : i18next.t('loc:Vo formulári sa nachádza chyba!')
		return notification.error(
			customMessage || {
				message: i18next.t('loc:Chybne vyplnený formulár'),
				description: i18next.t('loc:Skontrolujte správnosť vyplnených polí vo formulári. {{ errors }}', { errors: errorsText })
			}
		)
	}
	return undefined
}

/**
 * Recursively flatten a nested array of any depth
 * and
 * optionally map output
 */
export const flattenTree = (array: any[], callback?: (item: any, level: number) => any, nestingKey = 'children', levelOfDepth = 0) => {
	let output: any[] = []

	array.forEach((item: any) => {
		output.push(callback ? callback(item, levelOfDepth) : item)
		output = output.concat(flattenTree(item[nestingKey] || [], callback, nestingKey, levelOfDepth + 1))
	})

	return output
}

export const findNodeInTree = (node: any, value: any, valueKey = 'id', nestingKey = 'children') => {
	let result = null
	if (node) {
		if (node[valueKey] === value) {
			return node
		}
		if (node[nestingKey]) {
			// eslint-disable-next-line no-return-assign
			node[nestingKey].some((childrenNode: any) => {
				result = findNodeInTree(childrenNode, value, valueKey, nestingKey)
				return !!result
			})
		}
	}
	return result
}

export const getCountryPrefix = (countriesData?: NonNullable<RequestResponse<GetUrls['/api/b2b/admin/config/']>>['allCountries'] | null, countryCode?: string) => {
	const country = countriesData?.find((c) => c.code.toLowerCase() === countryCode?.toLowerCase())
	return country?.phonePrefix
}

/**
 * Remove accent and transform to lower case
 * Usefull for searching on FE
 * @link https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 */
export const transformToLowerCaseWithoutAccent = (source?: string): string =>
	source
		? source
				.toLowerCase()
				.normalize('NFD')
				.replace(/\p{Diacritic}/gu, '')
		: ''

export const sortData = (a?: any, b?: any) => {
	if (!isNil(a) && !isNil(b)) {
		const aValue = typeof a === 'string' ? transformToLowerCaseWithoutAccent(a) : a
		const bValue = typeof b === 'string' ? transformToLowerCaseWithoutAccent(b) : b

		if (aValue < bValue) {
			return -1
		}
		if (aValue > bValue) {
			return 1
		}
	}

	return 0
}

export const optionRenderWithImage = (itemData: any, fallbackIcon?: React.ReactNode, imageWidth = 24, imageHeight = 24) => {
	const { label, extra } = itemData
	const style = { width: imageWidth, height: imageHeight }

	return (
		<div className='flex items-center'>
			{extra?.image ? (
				<img className={'option-render-image'} style={style} src={extra.image} alt={label} />
			) : (
				<div className={'option-render-image fallback-icon'} style={style}>
					{fallbackIcon}
				</div>
			)}
			{label}
		</div>
	)
}

export const optionRenderWithIcon = (itemData: any, fallbackIcon?: React.ReactNode, imageWidth = 16, imageHeight = 16) => {
	const { label, icon } = itemData
	const style = { width: imageWidth, height: imageHeight }
	return (
		<div className='flex items-center'>
			<div style={style} className={'mr-4 flex items-center'}>
				{icon || fallbackIcon}
			</div>
			<span className={`truncate inline-block ${CYPRESS_CLASS_NAMES.OPTION_RENDER_ICON_TEXT}`}>{label}</span>
		</div>
	)
}

export const optionRenderWithAvatar = (itemData: any, imageWidth = 24, imageHeight = 24) => {
	// Thumbnail, label, extraContent (pod labelom)
	const { label, extra } = itemData
	const { color, thumbnail, extraContent, isDeleted } = extra || {}
	const style = { width: imageWidth, height: imageHeight, border: `2px solid ${color}`, filter: isDeleted ? 'grayscale(100%)' : undefined }
	return (
		<div className='flex items-center'>
			<img className={'rounded-full mr-2'} width={imageWidth} height={imageHeight} style={style} src={thumbnail} alt={label} />
			<div className={'flex flex-col leading-none'}>
				<div>{label}</div>
				<div>{extraContent}</div>
			</div>
		</div>
	)
}

export const optionRenderWithTag = (itemData: any) => {
	const { value, label, tagClassName } = itemData
	return (
		<Tag key={value} className={cx('noti-tag', tagClassName)}>
			<span>{label}</span>
		</Tag>
	)
}

export const optionRenderWithServiceColor = (label: string, color: string, title?: string) => {
	return (
		<div className='flex items-center gap-2' title={title}>
			<div className={'flex-shrink-0 w-4 h-4 rounded-md bg-notino-white overflow-hidden'} style={{ border: `1px solid ${color}` }}>
				<div style={{ background: color }} className={'opacity-20 w-full h-full'} />
			</div>
			<span className='truncate inline-block'>{label}</span>
		</div>
	)
}

export const formatLongQueryString = (search: string, limit?: number) => {
	const maxQueryLimit = limit || QUERY_LIMIT.MAX_255
	let formattedSearch = search
	if (search.length > maxQueryLimit) {
		formattedSearch = search.slice(0, maxQueryLimit)
	}
	return formattedSearch
}

export const showNotifications = (messages: IErrorMessage[]) => {
	if (!isEmpty(messages)) {
		forEach(messages, (message) => {
			let notif
			switch (lowerCase(message.type)) {
				case 'warning':
					notif = notification.warning
					break
				case 'success':
					notif = notification.success
					break
				case 'error':
					notif = notification.error
					break
				case 'info':
				default:
					notif = notification.info
					break
			}
			notif({
				closeIcon: <NotifCloseIcon className={'text-gray-100 hover:text-gray-200'} />,
				message: message.title || translateMessageType(message.type),
				description: message.message,
				className: 'noti-notification'
			})
		})
	}
}

export const showNotificationModal = (
	config: ModalFuncProps & {
		message?: string
		actionButtonLabel?: string
		action?: (e: any) => void
		notifactionType?: ResultStatusType
	} = {}
) => {
	const { message, actionButtonLabel, action, notifactionType, ...modalConfig } = config

	const modal = Modal.info({ open: false })

	modal.update({
		open: true,
		className: 'noti-notification-modal',
		closable: true,
		footer: false,
		maskClosable: true,
		keyboard: true,
		content: (
			<Result
				status={notifactionType || 'error'}
				title={message}
				extra={
					<Button
						className={'noti-btn'}
						onClick={(e) => {
							if (action) {
								action(e)
							}

							modal.destroy()
						}}
						type='primary'
					>
						{actionButtonLabel || i18next.t('loc:Zatvoriť')}
					</Button>
				}
			/>
		),
		...modalConfig
	})
}

export const checkUploadingBeforeSubmit = (values: any, dispatch: any, props: any) => {
	const { form } = props

	if (values && values[UPLOAD_IN_PROGRESS_PROP]) {
		const error = i18next.t('loc:Prebieha nahrávanie')
		showNotifications([{ type: MSG_TYPE.ERROR, message: error }])
		throw new SubmissionError({
			[UPLOAD_IN_PROGRESS_PROP]: error
		})
	} else {
		dispatch(submit(form))
	}
}

export const hasAuthUserPermissionToEditRole = (
	salonID?: string,
	authUser?: IAuthUserPayload['data'],
	employee?: IEmployeePayload['data'],
	salonRoles?: ISelectOptionItem[]
): { hasPermission: boolean; tooltip: string | null } => {
	const result: { hasPermission: boolean; tooltip: string | null } = {
		hasPermission: false,
		tooltip: i18next.t('loc:Pre túto akciu nemáte dostatočné oprávnenia.')
	}

	if (!salonID || !authUser || !employee || !salonRoles) {
		return result
	}

	if (authUser.uniqPermissions?.some((permission) => [PERMISSION.NOTINO_SUPER_ADMIN, PERMISSION.NOTINO, PERMISSION.PARTNER_ADMIN].includes(permission as any))) {
		// admin and super admin roles have access to all salons, so salons array in authUser data is empty (no need to list there all existing salons)
		return {
			hasPermission: true,
			tooltip: null
		}
	}
	if (authUser.id === employee?.employee?.user?.id) {
		// salon user can't edit his own role
		return {
			...result,
			tooltip: i18next.t('loc:Nemôžeš editovať svoju rolu')
		}
	}

	const authUserSalonRole = authUser.salons?.find((salon) => salon.id === salonID)?.role
	if (authUserSalonRole) {
		const authUserRoleIndex = salonRoles.findIndex((role) => role?.value === authUserSalonRole?.id)

		const employeeRole = employee.employee?.role
		const employeeRoleIndex = salonRoles.findIndex((role) => role?.value === employeeRole?.id)

		// it's not possible to edit role with same permissions (applies to admin role as well)
		if (employeeRoleIndex === authUserRoleIndex) {
			return {
				...result,
				tooltip: i18next.t('loc:Nie je možné editovať rolu s rovnakými oprávneniami, ako máte pridelené.')
			}
		}

		if (authUserRoleIndex === 0) {
			// is salon admin - has all permissions
			return {
				hasPermission: true,
				tooltip: null
			}
		}

		// it's possible to edit role only if you have permission to edit
		if (authUserSalonRole?.permissions.find((permission) => permission.name === PERMISSION.EMPLOYEE_ROLE_UPDATE)) {
			return {
				hasPermission: true,
				tooltip: null
			}
		}
		return result
	}
	return result
}

export const filterSalonRolesByPermission = (salonID?: string, authUser?: IAuthUserPayload['data'], salonRoles?: ISelectOptionItem[]) => {
	if (!salonID || !authUser || !salonRoles) {
		return salonRoles
	}

	if (authUser?.uniqPermissions?.some((permission) => [PERMISSION.PARTNER_ADMIN].includes(permission as any))) {
		// admin and super admin roles have access to all salons, so salons array in authUser data is empty (no need to list there all existing salons)
		// they automatically see all options
		return salonRoles
	}
	// other salon roles can assign only options that they have permission on
	const authUserSalonRole = authUser?.salons?.find((salon) => salon.id === salonID)?.role
	if (authUserSalonRole) {
		const highestUserRoleIndex = salonRoles.findIndex((role) => role?.value === authUserSalonRole?.id)
		if (highestUserRoleIndex === 0) {
			// is admin - has permission to asign all roles
			return salonRoles
		}
		// lower roles can assign all roles execpt admin
		const authUserDisabledRolesOptions = salonRoles.slice(0, 1).map((option) => ({ ...option, disabled: true }))
		const authUserAllowedRolesOptions = salonRoles.slice(1)
		return [...authUserDisabledRolesOptions, ...authUserAllowedRolesOptions]
	}

	return salonRoles
}

/**
 * Split array into two arrays by condition.
 * Example: splitArrayByCondition([1, 2, 5, 2, 9], (item) => item > 2) => [[5, 9], [1, 2, 2]]
 */
export const splitArrayByCondition = (source: any[], condition: (item: any) => boolean): any[][] => {
	return source.reduce(
		([pass, fail], item) => {
			return condition(item) ? [[...pass, item], fail] : [pass, [...fail, item]]
		},
		[[], []]
	)
}

export const getSalonFilterRanges = (values?: IDateTimeFilterOption[]): { [key: string]: Dayjs[] } => {
	const options = values ?? Object.values(DEFAULT_DATE_TIME_OPTIONS())
	const now = dayjs()
	return options.reduce((ranges, value) => {
		return {
			...ranges,
			[value.name]: [now.subtract(value.value, value.unit), now]
		}
	}, {})
}

export const getRangesForDatePicker = (values?: IDateTimeFilterOption[]): { [key: string]: Dayjs[] } => {
	const options = values ?? Object.values(DEFAULT_DATE_TIME_OPTIONS())
	const now = dayjs()
	return options.reduce((ranges, value) => {
		return [
			...ranges,
			{
				label: value.name,
				value: [now.subtract(value.value, value.unit), now]
			}
		]
	}, [])
}

export const getDateTime = (date: string, time: string) => {
	return dayjs(`${dayjs(date).format(DATE_TIME_PARSER_DATE_FORMAT)}:${time}`, DATE_TIME_PARSER_FORMAT).toISOString()
}

export const getAssignedUserLabel = (assignedUser?: RequestResponse<GetUrls['/api/b2b/admin/salons/']>['salons'][0]['assignedUser'], emailPriority = false): string => {
	if (!assignedUser) {
		return '-'
	}

	if (emailPriority) {
		if (assignedUser.email) {
			return `${assignedUser.email}`
		}

		if (assignedUser.firstName && assignedUser.lastName) {
			return `${assignedUser.firstName} ${assignedUser.lastName}`
		}
	} else {
		if (assignedUser.firstName && assignedUser.lastName) {
			return `${assignedUser.firstName} ${assignedUser.lastName}`
		}

		if (assignedUser.email) {
			return `${assignedUser.email}`
		}
	}

	if (assignedUser.firstName) {
		return `${assignedUser.firstName}`
	}

	return assignedUser.id
}

export const getCustomerName = (data: { firstName?: string; lastName?: string; email?: string; id: string } | undefined) => {
	if (!data) {
		return '-'
	}

	switch (true) {
		case !!data.firstName && !!data.lastName:
			return `${data.firstName} ${data.lastName}`
		case !!data.lastName:
			return `${data.lastName}`
		case !!data.email:
			return `${data.email}`
		default:
			return data.id
	}
}

export const normalizeDataById = <T extends { id: string }>(data?: T[] | null): { [key: string]: T } => {
	const normalizedData: { [key: string]: T } = {}
	data?.forEach((item) => {
		normalizedData[item.id] = item
	})
	return normalizedData
}

export const detectBrowserType = (): string => {
	const parser = new UAParser()

	const browser = parser.getBrowser()
	const browserName = browser.name?.toLowerCase()
	// get major number from version '101.4.11' -> 101, '94' -> 94
	// eslint-disable-next-line radix
	const majorVersion = parseInt(browser.version ? browser.version.split('.')[0] : '0')

	let browserType = BROWSER_TYPE.SUPPORTED

	try {
		if (!isEnumValue(browserName, BROWSERS)) {
			browserType = BROWSER_TYPE.UNKNOWN
		} else if (MIN_SUPPORTED_BROWSER_VERSION(browserName) > majorVersion) {
			browserType = BROWSER_TYPE.UNSUPPORTED
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error during browser version detection', error)
	}

	return browserType
}

export const getExpandIcon = (isActive: boolean, iconSize = 24, color = '#000') => (
	<ChevronDown
		width={iconSize}
		height={iconSize}
		color={color}
		style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 200ms ease-in-out', transformOrigin: 'center' }}
	/>
)

export const getRelativeTimeValue = (createdAt?: string) => {
	if (!createdAt) {
		return ''
	}

	dayjs.locale('en')

	let relativeTime = ''

	const diff = dayjs().diff(dayjs(createdAt), 'minute')

	if (diff < 60) {
		relativeTime = `${diff}${i18next.t('loc:_UNIT_minute_short')}`
	} else if (diff < 1440) {
		relativeTime = `${Math.floor(diff / 60)}${i18next.t('loc:_UNIT_hour_short')}`
	} else if (diff < 10080) {
		relativeTime = `${Math.floor(diff / 1440)}${i18next.t('loc:_UNIT_day_short')}`
	} else if (diff < 525600) {
		relativeTime = `${Math.floor(diff / 10080)}${i18next.t('loc:_UNIT_week_short')}`
	} else {
		relativeTime = `${Math.floor(diff / 525600)}${i18next.t('loc:_UNIT_year_short')}`
	}

	return relativeTime
}

export const RS_NOTIFICATION_FIELD_TEXTS = (notificationType: RsNotificationType) => {
	const result = {
		title: '',
		tooltip: ''
	}

	switch (notificationType) {
		case RS_NOTIFICATION_CUSTOMER.RESERVATION_AWAITING_APPROVAL_CUSTOMER:
		case RS_NOTIFICATION_EMPLOYEE.RESERVATION_AWAITING_APPROVAL_EMPLOYEE: {
			result.title = i18next.t('loc:Rezervácia čakajúca na schválenie')
			result.tooltip =
				notificationType === RS_NOTIFICATION_CUSTOMER.RESERVATION_AWAITING_APPROVAL_CUSTOMER
					? i18next.t('loc:Zákazník dostane notifikáciu, že jeho rezervácia čaká na schválenie salónom.')
					: i18next.t('loc:Zamestnanec dostane notifikáciu, že rezervácia vytvorená zákazníkom čaká na schválenie.')
			break
		}
		case RS_NOTIFICATION_CUSTOMER.RESERVATION_CONFIRMED_CUSTOMER:
		case RS_NOTIFICATION_EMPLOYEE.RESERVATION_CONFIRMED_EMPLOYEE: {
			const entity = notificationType === RS_NOTIFICATION_CUSTOMER.RESERVATION_CONFIRMED_CUSTOMER ? i18next.t('loc:Zákazník') : i18next.t('loc:Zamestnanec')
			result.title = i18next.t('loc:Potvrdenie rezervácie')
			result.tooltip = i18next.t('loc:{{entity}} dostane notifikáciu, že jeho rezervácia bola potvrdená.', { entity })
			break
		}

		case RS_NOTIFICATION_CUSTOMER.RESERVATION_CHANGED_CUSTOMER:
		case RS_NOTIFICATION_EMPLOYEE.RESERVATION_CHANGED_EMPLOYEE: {
			result.title = i18next.t('loc:Zmena rezervácie')
			result.tooltip =
				notificationType === RS_NOTIFICATION_CUSTOMER.RESERVATION_CHANGED_CUSTOMER
					? i18next.t('loc:Zákazník dostane notifikáciu, že jeho rezervácia bola zmenená salónom.')
					: i18next.t('loc:Zamestnanec dostane notifikáciu, že jeho rezervácia bola zmenená.')
			break
		}
		case RS_NOTIFICATION_CUSTOMER.RESERVATION_CANCELLED_CUSTOMER:
		case RS_NOTIFICATION_EMPLOYEE.RESERVATION_CANCELLED_EMPLOYEE: {
			const entity = notificationType === RS_NOTIFICATION_CUSTOMER.RESERVATION_CANCELLED_CUSTOMER ? i18next.t('loc:Zákazník') : i18next.t('loc:Zamestnanec')
			result.title = i18next.t('loc:Zrušenie rezervácie')
			result.tooltip = i18next.t('loc:{{entity}} dostane notifikáciu, že jeho rezervácia bola zrušená.', { entity })
			break
		}
		case RS_NOTIFICATION_CUSTOMER.RESERVATION_REJECTED_CUSTOMER: {
			const entity = i18next.t('loc:Zákazník')
			result.title = i18next.t('loc:Zamietnutie rezervácie')
			result.tooltip = i18next.t('loc:{{entity}} dostane notifikáciu, že jeho rezervácia bola zamietnutá salónom.', { entity })
			break
		}
		case RS_NOTIFICATION_CUSTOMER.RESERVATION_REMINDER_CUSTOMER: {
			const entity = i18next.t('loc:Zákazník')
			result.title = i18next.t('loc:Pripomenutie rezervácie')
			result.tooltip = i18next.t('loc:{{entity}} dostane deň vopred notifikáciu o blížiacom sa termíne rezervácie.', { entity })
			break
		}
		default:
			break
	}

	return result
}

export const getFilterPaths = (countryCode?: string, from?: string, to?: string) => {
	return {
		SALONS: {
			[SALON_FILTER_STATES.PUBLISHED]: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				statuses_published: SALON_FILTER_STATES.PUBLISHED,
				countryCode
			})}`,
			[SALON_FILTER_STATES.NOT_PUBLISHED]: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				statuses_published: SALON_FILTER_STATES.NOT_PUBLISHED,
				countryCode
			})}`,
			[SALON_FILTER_STATES.DECLINED]: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				statuses_changes: SALON_FILTER_STATES.DECLINED,
				countryCode
			})}`,
			[SALON_FILTER_STATES.PENDING_PUBLICATION]: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				statuses_changes: SALON_FILTER_STATES.PENDING_PUBLICATION,
				countryCode
			})}`,
			[SALON_CREATE_TYPE.BASIC]: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				createType: SALON_CREATE_TYPE.BASIC,
				countryCode
			})}`,
			changesOverPeriod: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				lastUpdatedAtFrom: from,
				lastUpdatedAtTo: to,
				countryCode
			})}`,
			publishedBasics: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				createType: SALON_CREATE_TYPE.BASIC,
				statuses_published: SALON_FILTER_STATES.PUBLISHED,
				countryCode
			})}`,
			publishedPremiums: `${i18next.t('paths:salons')}${formatObjToQuery<ISalonsActivePageURLQueryParams>({
				createType: SALON_CREATE_TYPE.NON_BASIC,
				statuses_published: SALON_FILTER_STATES.PUBLISHED,
				countryCode
			})}`
		}
	}
}

export const getStringifiedLanguages = (languages?: string[]) => {
	return (languages || []).reduce((acc, lng, index, arr) => {
		const { countryCode } = getLocale(lng)
		if (!countryCode) {
			return acc
		}
		if (index !== arr.length - 1) {
			return `${acc}${countryCode}, `
		}
		return `${acc}${countryCode}`
	}, '')
}

export const CHANGELOG_PLATFORM_TRANSLATIONS = (): Record<CHANGELOG_PLATFORM, string> => ({
	[CHANGELOG_PLATFORM.B2B_ADMIN]: i18next.t('loc:Admin'),
	[CHANGELOG_PLATFORM.B2C_APP]: i18next.t('loc:B2C Mobilná aplikácia'),
	[CHANGELOG_PLATFORM.B2B_APP]: i18next.t('loc:B2B Mobilná aplikácia')
})

export const CHANGELOG_PLATFORM_OPTIONS = (): ISelectOptionItem[] =>
	Object.values(CHANGELOG_PLATFORM).map((option) => ({
		value: option,
		key: option,
		label: CHANGELOG_PLATFORM_TRANSLATIONS()[option]
	}))

export const getChangelogStatusTag = (changelogStatus: string) => {
	switch (changelogStatus) {
		case CHANGELOG_STATUS.PUBLISHED:
			return (
				<Tag className={'noti-tag bg-status-published'}>
					<span>{i18next.t('loc:Publikovaný')}</span>
				</Tag>
			)
		case CHANGELOG_STATUS.DRAFT:
			return (
				<Tag className={'noti-tag bg-status-notPublished'}>
					<span>{i18next.t('loc:Koncept')}</span>
				</Tag>
			)
		default:
			return null
	}
}

export const formatImgFormValues = (fileList: UploadChangeParam<UploadFile & { id?: string }>['fileList'], filesData: FileUploadParam) => {
	return fileList.map((fileListItem) => {
		const fileData: FileUploadData | undefined = filesData[fileListItem.uid]

		const file: UploadFieldValueType = {
			...fileListItem,
			id: fileListItem.id || fileData?.id,
			url: fileListItem.url || fileData?.path
		}

		return file
	})
}

export const formatFileFormValues = (fileList: UploadChangeParam<UploadFile & { id?: string }>['fileList'], filesData: FileUploadParam) => {
	return fileList.map((fileListItem) => {
		const fileData: FileUploadData | undefined = filesData[fileListItem.uid]

		const file: UploadFieldValueType = {
			...fileListItem,
			id: fileListItem.id || fileData?.id,
			url: fileListItem.url || fileData?.path
		}

		return file
	})
}

export const CALENDAR_COLORS: Record<CALENDAR_EVENT_TYPE, CalendarEvent['color']> &
	Record<'DELETED_EMPLOYEE', '#808080'> &
	Record<'ACTIVE_EMPLOYEE', '#000'> &
	Record<'DEFAULT', '#CA8504'> = {
	[CALENDAR_EVENT_TYPE.RESERVATION]: '#CA8504',
	[CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT]: '#CA8504',
	[CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF]: '#6D7483',
	[CALENDAR_EVENT_TYPE.EMPLOYEE_BREAK]: '#383B44',
	DELETED_EMPLOYEE: '#808080',
	ACTIVE_EMPLOYEE: '#000',
	DEFAULT: '#CA8504'
}

export const SERVICE_DEFAULT_COLOR: RequestResponse<GetUrls['/api/b2b/admin/enums/service-colors/']>['colors'][0]['hex'] = '#CA8504'

export const millisecondsToMinutes = (milliseconds: number): number => {
	const minutes = milliseconds / 60000
	return minutes
}

export const getReCaptchaErrorMessage = (
	errorType: ERROR_MSG_CODE.RECAPTCHA_TOKEN_NOT_PROVIDED | ERROR_MSG_CODE.RECAPTCHA_VERIFICATION_FAILED,
	error: AxiosError | Error | any
): IErrorMessage => {
	Sentry.captureException(error)
	Sentry.captureMessage(`ErrorType: ${errorType}. ErrorMsg: ${error?.message}`)

	if (errorType === ERROR_MSG_CODE.RECAPTCHA_TOKEN_NOT_PROVIDED) {
		return {
			type: MSG_TYPE.ERROR,
			message: (
				<Trans
					defaults={i18next.t(
						'loc:Vaša odoslaná žiadosť bola zablokovaná systémom reCAPTCHA. <b>Prosím, skúste to znova</b>. Ak problém pretrváva, kontaktujte našu <button>technickú podporu</button>.'
					)}
					components={{
						b: <b />,
						button: (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label
							<button type={'button'} onClick={() => Navigator.navigate(i18next.t('paths:contact'))} className={'underline outline-none border-0 bg-transparent'} />
						)
					}}
				/>
			)
		}
	}

	return {
		type: MSG_TYPE.ERROR,
		message: (
			<Trans
				defaults={i18next.t(
					'loc:Nepodarilo sa overiť vašu aktivitu systémom reCAPTCHA. <b>Prosím, skúste to znova</b>. Ak problém pretrváva, kontaktujte našu <button>technickú podporu</button>'
				)}
				components={{
					b: <b />,
					button: (
						// eslint-disable-next-line jsx-a11y/control-has-associated-label
						<button type={'button'} onClick={() => Navigator.navigate(i18next.t('paths:contact'))} className={'underline outline-none border-0 bg-transparent'} />
					)
				}}
			/>
		)
	}
}

export const SHORTCUT_DAYS_OPTIONS = (length = 2): CheckboxGroupOption[] =>
	Object.entries(SHORTCUT_DAYS_TRANSLATIONS(length)).map(([value, label]) => ({
		key: value,
		label,
		value: value as DAY
	}))

export const parseTimeFromMinutes = (minutes: number) => {
	const days = Math.floor(minutes / 1440)
	const hoursLeft = minutes % 1440
	const hours = Math.floor(hoursLeft / 60)
	const min = hoursLeft % 60

	return `${days ? `${days}${'d'} ${hours}h` : ''} ${!days && hours ? `${hours}${'h'}` : ''} ${min ? `${min}${'m'}` : ''}`.trim()
}

const timeTextOptions = { dateStyle: null, fallback: '-' }

export const getTimeText = (start: string | Date | null, end: string | Date | null, onlyStart = false) => {
	if (onlyStart) {
		return formatDateTimeByLocale(start, timeTextOptions)
	}

	return formatDateTimeRangeByLocale(start, end, timeTextOptions)
}

const isEntityNotified = (disabledNotificationSource: DisabledNotificationsArray[0]) => {
	let notificationChannelsLength = Object.keys(RS_NOTIFICATION_CHANNEL).length

	// not all notification event types get SMS notification
	if (EXCLUDED_SMS_NOTIFICATIONS.includes(disabledNotificationSource?.eventType as RsNotificationType)) {
		notificationChannelsLength -= 1
	}

	// when array length is equal to notificationChannelsLength it means all notifications are disabled for entity
	if (disabledNotificationSource?.channels?.length === notificationChannelsLength) {
		return false
	}

	return true
}

/**
 * @param baseNotificationText base notification text
 * @param disabledNotificationTypes array of disabled notification types to check if they are included in disabled notications types source
 * @param disabledNotificationsSource source of disabled notifications types
 * @return string
 *
 *  Return base notification text including information whether employee, customer, both or none of them will be notified
 *
 */
export const getConfirmModalText = (
	baseNotificationText: string,
	disabledNotificationTypesToCheck: CALENDAR_DISABLED_NOTIFICATION_TYPE[],
	disabledNotificationsSource?: DisabledNotificationsArray,
	ignoreCustomerNotification = false,
	ignoreEmployeeNotification = false
) => {
	let isCustomerNotified = !ignoreCustomerNotification
	let isEmployeeNotified = !ignoreEmployeeNotification

	if (!ignoreCustomerNotification || !ignoreEmployeeNotification) {
		disabledNotificationTypesToCheck.forEach((notificationToCheck) => {
			const disabledNotificationSource = disabledNotificationsSource?.find((notificationSource) => notificationSource?.eventType === notificationToCheck)

			if (disabledNotificationSource) {
				if (disabledNotificationSource.eventType?.endsWith('CUSTOMER')) {
					isCustomerNotified = isEntityNotified(disabledNotificationSource)
				}

				if (disabledNotificationSource.eventType?.endsWith('EMPLOYEE')) {
					isEmployeeNotified = isEntityNotified(disabledNotificationSource)
				}
			}
		})
	}

	if (isCustomerNotified && isEmployeeNotified) {
		return i18next.t('loc:{{baseNotificationText}} Zamestnanec aj zákazník dostanú notifikáciu.', { baseNotificationText })
	}

	const notificationText = (entity: string) => i18next.t('loc:{{baseNotificationText}} {{entity}} dostane notifikáciu.', { entity, baseNotificationText })

	if (isCustomerNotified) {
		return notificationText(i18next.t('loc:Zákazník'))
	}

	if (isEmployeeNotified) {
		return notificationText(i18next.t('loc:Zamestnanec'))
	}

	return baseNotificationText
}

export const validateReservationAndShowNotification = ({ serviceId, customerId }: { serviceId?: string | number; customerId?: string | number }) => {
	const errors: { message: string; description: string }[] = []

	if (!serviceId) {
		errors.push({
			message: i18next.t('loc:Služba nie je zadaná'),
			description: i18next.t('loc:Nie je možné editovať rezerváciu bez zadanej služby')
		})
	}

	if (!customerId) {
		errors.push({
			message: i18next.t('loc:Zákazník nie je zadaný'),
			description: i18next.t('loc:Nie je možné editovať rezerváciu bez zadaného zákazníka')
		})
	}

	if (!isEmpty(errors)) {
		errors.forEach((error) => notification.error(error))
	}

	return errors
}
