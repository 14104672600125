import { useEffect, useRef } from 'react'
import dayjs from 'dayjs'

// utils
import { GaScreenDurationEvent, GaScreenViewEvent, pushEventToDataLayer } from '../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_SCREEN_CLASS, GA_SCREEN_NAME } from '../utils/dataLayerEnums'

export type OtherDataType = {
	source?: string
}

/**
 * push screen_view event to dataLayer on modal open
 * push screen_duration event to dataLayer on modal close (or when user closes the tab / browser)
 */
const usePushModalScreenInfoToDataLayer = (isOpen: boolean, screenName: GA_SCREEN_NAME, screenClass: GA_SCREEN_CLASS, otherData: OtherDataType = {}) => {
	const startTimeRef = useRef<dayjs.Dayjs | null>(null)
	const { source } = otherData

	useEffect(() => {
		const onModalClose = (start: dayjs.Dayjs) => {
			let dataLayerScreenDurationEvent: GaScreenDurationEvent & OtherDataType = {
				event: DATA_LAYER_EVENTS.SCREEN_DURATION,
				screen_name: screenName,
				screen_time: dayjs().diff(start, 'ms')
			}

			if (source) {
				dataLayerScreenDurationEvent = {
					...dataLayerScreenDurationEvent,
					source
				}
			}

			pushEventToDataLayer(dataLayerScreenDurationEvent)
		}

		if (isOpen) {
			startTimeRef.current = dayjs()

			let dataLayerScreenViewEvent: GaScreenViewEvent & OtherDataType = { event: DATA_LAYER_EVENTS.SCREEN_VIEW, screen_name: screenName, screen_class: screenClass }

			if (source) {
				dataLayerScreenViewEvent = {
					...dataLayerScreenViewEvent,
					source
				}
			}

			pushEventToDataLayer(dataLayerScreenViewEvent)
			return
		}

		if (!startTimeRef.current) {
			return
		}

		onModalClose(startTimeRef.current)

		const onBeforeUnload = () => {
			if (startTimeRef.current) {
				onModalClose(startTimeRef.current)
			}
		}
		window.addEventListener('beforeunload', onBeforeUnload)

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload)
		}
	}, [screenName, screenClass, isOpen, source])

	return null
}

export default usePushModalScreenInfoToDataLayer
