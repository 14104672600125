import React, { PropsWithChildren } from 'react'
import { Button, ButtonProps, DrawerProps, Dropdown, PopoverProps } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { ItemType } from 'antd/es/menu/interface'

// utils
import { RESERVATION_PAYMENT_METHOD, RESERVATION_STATE, STRINGS } from '../../utils/enums'
import { getDateTime, translateReservationPaymentMethod, translateReservationState } from '../../utils/helper'

// types
import { CalendarEvent, CalendarEventDetail, HandleUpdateReservationStateFunc } from '../../types/interfaces'
import { ISelectedSalonPayload } from '../../reducers/selectedSalon/selectedSalonActions'
import { ISalonPayload } from '../../reducers/salons/salonsActions'

// assets
import ChevronDown from '../../assets/icons/chevron-down.svg?react'
import DollarIcon from '../../assets/icons/dollar-icon.svg?react'
import CloseIcon from '../../assets/icons/close-icon.svg?react'
import CrossedIcon from '../../assets/icons/close-circle-icon.svg?react'
import RefreshIcon from '../../assets/icons/refresh-icon.svg?react'
import EditIcon from '../../assets/icons/edit-icon.svg?react'
import FullscreenIcon from '../../assets/icons/fullscreen-icon.svg?react'

type CalendarEventData = (CalendarEvent | CalendarEventDetail) & { employee: { deletedAt?: string } }
type SalonData = ISelectedSalonPayload['data'] | NonNullable<ISalonPayload['data']>['salon']
export type ReservationDetailProps = {
	isOpen: boolean
	setIsOpen: (open: boolean) => void
	calendarEvent: CalendarEventData
	salonData: SalonData | undefined
	handleUpdateReservationState: HandleUpdateReservationStateFunc
	color: string | undefined
	loading?: boolean
	footerButtons?: {
		onMarkAsPaidClick?: () => void
		onPayByCardClick?: () => void
		onPayByCashClick?: () => void
		onPayByOtherPaymentMethod?: () => void
		onDeclineReservationClick?: () => void
		onNotRealizedReservationClick?: () => void
		onApproveReservationClick?: () => void
	}
	headerButtons?: {
		onOpenInCalendar?: (calendarEvent: CalendarEventData) => void
		onEditEvent?: (calendarEvent: CalendarEventData) => void
		onRepeatReservation?: (calendarEventID: string) => void
		onCancelReservation?: () => void
		onMoreMenuItemsDropdownOpenChange?: (open: boolean) => void
	}
	fetchEventDetailAfterStateUpdate?: boolean
}

export type ReservationDetailPopoverProps = PropsWithChildren<
	ReservationDetailProps & {
		popoverProps?: Omit<PopoverProps, 'content'>
		isPast: boolean
	}
>

export type UnsavedChangesPopoverProps = PropsWithChildren<{
	isOpen: boolean
	setIsOpen: (open: boolean) => void
	popoverProps?: Omit<PopoverProps, 'content'>
	loading?: boolean
}>

export type ReservationDetailSidebarProps = ReservationDetailProps & {
	afterOpenChange?: (open: boolean) => void
	sidebarProps?: Omit<DrawerProps, 'open' | 'onClose' | 'afterOpenChange'>
}

const itemClassName = 'p-2 font-medium min-w-0 h-9 w-full relative'

export const getStateSpecificProps = (args: {
	data: ReservationDetailProps
	salonData: SalonData | undefined
	handleUpdateState: (reservationState: RESERVATION_STATE, paymentMethod?: RESERVATION_PAYMENT_METHOD) => void
	isNotinoUser: boolean
	isSidebar?: boolean
}) => {
	const { data, salonData, handleUpdateState, isNotinoUser, isSidebar = false } = args
	const { headerButtons, footerButtons: dataFooterButtons, calendarEvent, loading } = data
	const {
		onMarkAsPaidClick,
		onPayByCardClick,
		onPayByCashClick,
		onPayByOtherPaymentMethod,
		onDeclineReservationClick,
		onApproveReservationClick,
		onNotRealizedReservationClick
	} = dataFooterButtons || {}
	const { onRepeatReservation, onCancelReservation, onEditEvent, onOpenInCalendar } = headerButtons || {}
	const { reservationData, start } = calendarEvent
	const startDateTime = getDateTime(start.date, start.time)

	const getFooterCheckoutButton = () => {
		const items = []

		if (salonData?.payByCard) {
			const { icon } = translateReservationPaymentMethod(RESERVATION_PAYMENT_METHOD.CARD)
			items.push({
				key: 'realized-card',
				label: i18next.t('loc:Kartou'),
				icon,
				className: itemClassName,
				onClick: () => {
					if (onPayByCardClick) {
						onPayByCardClick()
					}
					handleUpdateState(RESERVATION_STATE.REALIZED, RESERVATION_PAYMENT_METHOD.CARD)
				}
			})
		}

		if (salonData?.payByCash) {
			const { icon } = translateReservationPaymentMethod(RESERVATION_PAYMENT_METHOD.CASH)
			items.push({
				key: 'realized-cash',
				label: i18next.t('loc:Hotovosťou'),
				icon,
				className: itemClassName,
				onClick: () => {
					if (onPayByCashClick) {
						onPayByCashClick()
					}
					handleUpdateState(RESERVATION_STATE.REALIZED, RESERVATION_PAYMENT_METHOD.CASH)
				}
			})
		}

		if (salonData?.otherPaymentMethods) {
			const { icon } = translateReservationPaymentMethod(RESERVATION_PAYMENT_METHOD.OTHER)
			items.push({
				key: 'realized-other',
				label: i18next.t('loc:Iným spôsobom'),
				icon,
				className: itemClassName,
				onClick: () => {
					if (onPayByOtherPaymentMethod) {
						onPayByOtherPaymentMethod()
					}
					handleUpdateState(RESERVATION_STATE.REALIZED, RESERVATION_PAYMENT_METHOD.OTHER)
				}
			})
		}

		const button = (buttonProps: ButtonProps & { key: string }) => {
			const { key, ...restProps } = buttonProps
			return (
				<Button
					key={key}
					type={'primary'}
					disabled={loading}
					size={'middle'}
					className={'noti-btn w-1/2'}
					htmlType={'button'}
					onClick={(e) => {
						e.preventDefault()

						if (onMarkAsPaidClick) {
							onMarkAsPaidClick()
						}
					}}
					{...restProps}
				>
					{i18next.t('loc:Zaplatená')}
				</Button>
			)
		}

		return items?.length ? (
			<Dropdown
				key={'footer-checkout-dropdown'}
				menu={{
					className: 'shadow-md max-w-xs min-w-48 w-48 mt-1 p-2 flex flex-col gap-2',
					items
				}}
				placement='bottomRight'
				trigger={['click']}
			>
				{button({ key: 'checkout-button-dropdown', icon: <ChevronDown className={'filter-invert max'} /> })}
			</Dropdown>
		) : (
			button({ key: 'checkout-button-realized', onClick: () => handleUpdateState(RESERVATION_STATE.REALIZED) })
		)
	}

	const getFooterCancelButton = (key: string, label: string, onClick: () => void) => (
		<Button key={key} disabled={loading} type={'dashed'} size={'middle'} className={'noti-btn w-1/2'} htmlType={'button'} onClick={onClick}>
			{label}
		</Button>
	)

	const headerMoreItems: Record<string, ItemType> = {
		cancel_by_salon: {
			key: 'cancel-by-salon',
			label: <span className={'text-notino-red'}>{STRINGS(i18next.t).cancel(i18next.t('loc:rezerváciu'))}</span>,
			icon: <CrossedIcon className={'text-danger small-icon'} />,
			className: itemClassName,
			onClick: () => {
				if (onCancelReservation) {
					onCancelReservation()
				}
				handleUpdateState(RESERVATION_STATE.CANCEL_BY_SALON)
			}
		},
		repeat_reservation: {
			key: 'repeat_reservation',
			label: i18next.t('loc:Zopakovať rezerváciu'),
			icon: <RefreshIcon className={'small-icon'} />,
			className: itemClassName,
			onClick: () => {
				if (onRepeatReservation) {
					onRepeatReservation(calendarEvent.id)
				}
			}
		},
		edit_reservation: {
			key: 'edit_reservation',
			label: i18next.t('loc:Upraviť rezerváciu'),
			icon: <EditIcon className={'small-icon'} />,
			className: itemClassName,
			onClick: () => {
				if (onEditEvent) {
					onEditEvent(calendarEvent)
				}
			}
		},
		open_in_calendar: {
			key: 'open_in_calendar',
			label: i18next.t('loc:Otvoriť v kalendári'),
			icon: <FullscreenIcon className={'small-icon'} />,
			className: itemClassName,
			onClick: () => {
				if (onOpenInCalendar) {
					onOpenInCalendar(calendarEvent)
				}
			}
		}
	}

	switch (reservationData?.state) {
		case RESERVATION_STATE.APPROVED: {
			const { icon: headerIcon, text: headerState } = translateReservationState(RESERVATION_STATE.APPROVED)
			let footerButtons: JSX.Element[] = []
			let moreMenuItems: ItemType[] = isSidebar ? [headerMoreItems.open_in_calendar, headerMoreItems.edit_reservation] : []
			moreMenuItems = [...moreMenuItems, headerMoreItems.repeat_reservation, headerMoreItems.cancel_by_salon]

			if (isNotinoUser || (!isNotinoUser && dayjs(startDateTime).isSameOrBefore(dayjs()))) {
				footerButtons = [
					getFooterCancelButton('cancel-button-not-realized', i18next.t('loc:Nezrealizovaná'), () => {
						if (onNotRealizedReservationClick) {
							onNotRealizedReservationClick()
						}
						handleUpdateState(RESERVATION_STATE.NOT_REALIZED)
					}),
					getFooterCheckoutButton()
				]
			}

			return {
				headerIcon,
				headerState,
				moreMenuItems,
				footerButtons
			}
		}
		case RESERVATION_STATE.PENDING: {
			const { icon: headerIcon, text: headerState } = translateReservationState(RESERVATION_STATE.PENDING)
			let moreMenuItems: ItemType[] = isSidebar ? [headerMoreItems.open_in_calendar, headerMoreItems.edit_reservation] : []
			moreMenuItems = [...moreMenuItems, headerMoreItems.cancel_by_salon]

			return {
				headerIcon,
				headerState,
				moreMenuItems,
				footerButtons: [
					getFooterCancelButton('cancel-button-declined', i18next.t('loc:Zamietnuť'), () => {
						if (onDeclineReservationClick) {
							onDeclineReservationClick()
						}
						handleUpdateState(RESERVATION_STATE.DECLINED)
					}),
					<Button
						key={'confirm-button'}
						type={'dashed'}
						size={'middle'}
						className={'noti-btn w-1/2'}
						disabled={loading}
						htmlType={'button'}
						onClick={() => {
							if (onApproveReservationClick) {
								onApproveReservationClick()
							}
							handleUpdateState(RESERVATION_STATE.APPROVED)
						}}
					>
						{i18next.t('loc:Potvrdiť')}
					</Button>
				]
			}
		}
		case RESERVATION_STATE.REALIZED: {
			let moreMenuItems: ItemType[] = isSidebar ? [headerMoreItems.open_in_calendar, headerMoreItems.edit_reservation] : []

			if (!reservationData?.paymentMethod) {
				return {
					headerIcon: <DollarIcon className={'text-notino-success'} />,
					headerState: i18next.t('loc:Zaplatená'),
					moreMenuItems,
					footerButtons: []
				}
			}

			const { icon: headerIcon, text: headerState } = translateReservationPaymentMethod(reservationData?.paymentMethod as RESERVATION_PAYMENT_METHOD, 'text-notino-success')
			moreMenuItems = [...moreMenuItems, headerMoreItems.repeat_reservation, headerMoreItems.cancel_by_salon]

			return { headerIcon, headerState, moreMenuItems, footerButtons: [] }
		}
		case RESERVATION_STATE.NOT_REALIZED: {
			const { icon: headerIcon, text: headerState } = translateReservationState(RESERVATION_STATE.NOT_REALIZED)
			let moreMenuItems: ItemType[] = isSidebar ? [headerMoreItems.open_in_calendar, headerMoreItems.edit_reservation] : []
			moreMenuItems = [...moreMenuItems, headerMoreItems.repeat_reservation, headerMoreItems.cancel_by_salon]

			return {
				headerIcon,
				headerState,
				moreMenuItems,
				footerButtons: []
			}
		}
		case RESERVATION_STATE.DECLINED:
		case RESERVATION_STATE.CANCEL_BY_CUSTOMER:
		case RESERVATION_STATE.CANCEL_BY_SALON:
		case RESERVATION_STATE.CANCEL_BY_SYSTEM:
		default: {
			const { icon: headerIcon, text: headerState } = translateReservationState(reservationData?.state as RESERVATION_STATE)
			let moreMenuItems: ItemType[] = isSidebar ? [headerMoreItems.edit_reservation] : []
			moreMenuItems = [...moreMenuItems, headerMoreItems.repeat_reservation]

			return {
				headerIcon,
				headerState,
				moreMenuItems,
				footerButtons: []
			}
		}
	}
}

export const getCloseButton = (onClose: () => void) => (
	<button className={'nc-popover-header-button relative'} type={'button'} onClick={onClose}>
		<span className={'absolute w-0 h-0 overflow-hidden opacity-0'}>{i18next.t('loc:Zavrieť')}</span>
		<CloseIcon />
	</button>
)
