import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

const BlacklistBadge = ({ className }: { className?: string }) => {
	const [t] = useTranslation()

	return <div className={cx('bg-notino-black text-notino-white py-[6px] px-2 rounded-full xs-semibold flex items-center', className)}>{t('loc:Blacklist')}</div>
}

export default BlacklistBadge
