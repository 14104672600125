import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, TabsProps } from 'antd'
import { compose } from 'redux'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import TabsComponent from '../../components/TabsComponent'
import DynamicNotificationsTab from './DynamicNotificationsTab'
import ChangelogsTab from './ChangelogsTab'

// utils
import { NOTIFICATIONS_TAB_KEYS, PERMISSION } from '../../utils/enums'
import { checkPermissions, withPermissions } from '../../utils/Permissions'

// types
import { IBreadcrumbs } from '../../types/interfaces'
import { RootState } from '../../reducers'

type Props = {
	tabKey: NOTIFICATIONS_TAB_KEYS
}

const NotificationsPage = (props: Props) => {
	const { tabKey } = props
	const [t] = useTranslation()
	const navigate = useNavigate()

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const hasPermissionToSeeChangelogs = checkPermissions(authUser.data?.uniqPermissions)

	useEffect(() => {
		if (tabKey === NOTIFICATIONS_TAB_KEYS.CHANGELOGS && !hasPermissionToSeeChangelogs) {
			navigate('/403')
		}
	}, [hasPermissionToSeeChangelogs, tabKey, navigate])

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam upozornení')
			}
		]
	}

	const onTabChange = useCallback(
		(newTabKey: string) => {
			if (newTabKey === NOTIFICATIONS_TAB_KEYS.DYNAMIC_NOTIFICATIONS) {
				navigate(t('paths:notifications'))
			} else if (newTabKey === NOTIFICATIONS_TAB_KEYS.CHANGELOGS) {
				navigate(t('paths:notifications/changelogs'))
			}
		},
		[navigate, t]
	)

	const tabContent: TabsProps['items'] = [
		{
			key: NOTIFICATIONS_TAB_KEYS.DYNAMIC_NOTIFICATIONS,
			label: <>{t('loc:Notifikácie')}</>,
			children: <DynamicNotificationsTab />
		}
	]

	if (hasPermissionToSeeChangelogs) {
		tabContent.push({
			key: NOTIFICATIONS_TAB_KEYS.CHANGELOGS,
			label: <>{t('loc:What’s New')}</>,
			children: <ChangelogsTab />
		})
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<div className='content-body'>
				<TabsComponent className={'box-tab'} activeKey={tabKey} onChange={onTabChange} items={tabContent} destroyInactiveTabPane />
			</div>
		</>
	)
}

export default compose(withPermissions([PERMISSION.DYNAMIC_NOTIFICATION_CREATE, PERMISSION.DYNAMIC_NOTIFICATION_BROWSING]))(NotificationsPage)
