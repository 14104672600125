import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Dropdown, Row, Spin, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { initialize } from 'redux-form'
import { ColumnsType, TableProps } from 'antd/es/table'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'

// components
import { isEmpty } from 'lodash'
import Breadcrumbs from '../../components/Breadcrumbs'
import CustomTable from '../../components/CustomTable'
import UserAvatar from '../../components/AvatarComponents'
import NotinoReservationsFilter from './components/NotinoReservationsFilter'
import ReservationDetailSidebar from '../../components/ReservationDetail/ReservationDetailSidebar'
import CalendarConfirmModal from '../../components/CalendarModalConfirm/CalendarConfirmModal'

// utils
import {
	ADMIN_RESERVATIONS_ORDER,
	CALENDAR_DATE_FORMAT,
	CALENDAR_EVENT_TYPE,
	CALENDAR_VIEW,
	CONFIRM_MODAL_DATA_TYPE,
	FORM,
	MSG_TYPE,
	PERMISSION,
	RESERVATION_FEEDBACK_STATE,
	RESERVATION_STATE,
	ROW_GUTTER_X_DEFAULT
} from '../../utils/enums'
import { withPermissions } from '../../utils/Permissions'
import {
	normalizeDirectionKeys,
	setOrder,
	showNotifications,
	translateReservationPaymentMethod,
	translateReservationState,
	validateReservationAndShowNotification
} from '../../utils/helper'
import { patchReq } from '../../utils/request'

// reducers
import { RootState } from '../../reducers'
import { setSelectedCountry } from '../../reducers/selectedCountry/selectedCountryActions'
import { getNotinoReservations, INotinoReservationsTableData } from '../../reducers/paginatedReservations/paginatedReservationsActions'
import { getCategories } from '../../reducers/categories/categoriesActions'
import { getCalendarEventDetail } from '../../reducers/calendar/calendarActions'
import { getSalon, ISalonPayload } from '../../reducers/salons/salonsActions'

// types
import { CalendarEventDetail, ConfirmModalData, HandleUpdateReservationStateFunc, IBreadcrumbs, INotinoReservationsFilter, PatchUrls, RequestPayload } from '../../types/interfaces'

// hooks
import useQueryParams, { formatObjToQuery } from '../../hooks/useQueryParamsZod'

// schema
import { notinoReservationsQueryParamsSchema } from '../../schemas/queryParams'
import { ICalendarPageURLQueryParams } from '../../types/schemaTypes'

// assets
import DotsIcon from '../../assets/icons/more-info-horizontal-icon.svg?react'
import EditIcon from '../../assets/icons/edit-icon.svg?react'
import RefreshIcon from '../../assets/icons/refresh-icon.svg?react'
import CloseIcon from '../../assets/icons/close-circle-icon.svg?react'
import FullscreenIcon from '../../assets/icons/fullscreen-icon.svg?react'

type PatchReservationStateBody = RequestPayload<PatchUrls['/api/b2b/admin/salons/{salonID}/calendar-events/reservations/{calendarEventID}/state']>
type ReservationCancellation = NonNullable<PatchReservationStateBody['reservationCancellation']>

type TableDataItem = NonNullable<INotinoReservationsTableData>

const NotinoReservationsPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const notinoReservations = useSelector((state: RootState) => state.paginatedReservations.notinoReservations)
	const selectedCountry = useSelector((state: RootState) => state.selectedCountry.selectedCountry)

	const [reservationSidebarData, setReservationSidebarData] = useState<{
		isOpen: boolean
		data: {
			calendarEvent: CalendarEventDetail
			salon: NonNullable<ISalonPayload['data']>['salon']
		} | null
	}>({
		isOpen: false,
		data: null
	})

	const [confirmModalData, setConfirmModalData] = useState<ConfirmModalData>(null)
	const [isLoadingCalenderEvent, setIsLoadingCalendarEvent] = useState(false)
	const [isSubmittingReservationState, setIsSubmittingReservationState] = useState(false)

	const loading = notinoReservations?.isLoading || isSubmittingReservationState || isLoadingCalenderEvent

	const [query, setQuery] = useQueryParams(notinoReservationsQueryParamsSchema, {
		page: 1,
		order: ADMIN_RESERVATIONS_ORDER.CREATED_AT_DESC
	})

	const countryCode = selectedCountry || query.countryCode

	useEffect(() => {
		dispatch(getCategories())
	}, [dispatch])

	const fetchReservations = useCallback(() => {
		dispatch(
			getNotinoReservations({
				dateFrom: query.dateFrom,
				dateTo: query.dateTo,
				createdAtFrom: query.createdAtFrom,
				createdAtTo: query.createdAtTo,
				reservationStates: query.reservationStates,
				reservationPaymentMethods: query.reservationPaymentMethods,
				reservationCreateSourceType: query.reservationCreateSourceType,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				countryCode,
				page: query.page,
				order: query.order,
				limit: query.limit,
				search: query.search
			})
		)
	}, [
		dispatch,
		query.categoryFirstLevelIDs,
		query.createdAtFrom,
		query.createdAtTo,
		query.dateFrom,
		query.dateTo,
		query.limit,
		query.page,
		query.reservationCreateSourceType,
		query.reservationPaymentMethods,
		query.reservationStates,
		query.search,
		query.order,
		countryCode
	])

	useEffect(() => {
		dispatch(
			initialize(FORM.NOTINO_RESERVATIONS_FILTER, {
				dateFrom: query.dateFrom,
				dateTo: query.dateTo,
				createdAtFrom: query.createdAtFrom,
				createdAtTo: query.createdAtTo,
				reservationStates: query.reservationStates,
				reservationPaymentMethods: query.reservationPaymentMethods,
				reservationCreateSourceType: query.reservationCreateSourceType,
				search: query.search,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				countryCode
			})
		)
		fetchReservations()
	}, [
		fetchReservations,
		dispatch,
		query.categoryFirstLevelIDs,
		query.createdAtFrom,
		query.createdAtTo,
		query.dateFrom,
		query.dateTo,
		query.limit,
		query.page,
		query.reservationCreateSourceType,
		query.reservationPaymentMethods,
		query.reservationStates,
		query.search,
		query.order,
		countryCode
	])

	const handleSubmit = (values: INotinoReservationsFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		// update selected country globally based on filter
		dispatch(setSelectedCountry(values?.countryCode || undefined))
		setQuery(newQuery)
	}

	const clearSidebarData = () => {
		setReservationSidebarData({ isOpen: false, data: null })
	}

	const clearConfirmModal = () => setConfirmModalData(null)

	const getCalendarEventData = async (salonID: string, eventID: string) => {
		setIsLoadingCalendarEvent(true)

		const [eventData, salonData] = await Promise.all([dispatch(getCalendarEventDetail(salonID, eventID)), dispatch(getSalon(salonID))])

		if (!eventData.data?.id || !salonData.data?.salon.id) {
			clearConfirmModal()
			clearSidebarData()
			showNotifications([
				{
					type: MSG_TYPE.ERROR,
					message: t('loc:Nepodarilo sa načítať detail rezervácie - {{ reservationID }}', {
						reservationID: eventID
					})
				}
			])
		}

		setIsLoadingCalendarEvent(false)

		return { calendarEvent: eventData.data, salon: salonData.data?.salon }
	}

	const handleUpdateReservationState: HandleUpdateReservationStateFunc = async ({
		calendarEventID,
		state,
		paymentMethod,
		eventData,
		fetchEventDetailAfterStateUpdate,
		quickFeedbackAnswers,
		text,
		salonID
	}) => {
		if (!salonID) {
			return
		}

		// The application should never reach this state, as reservations with statuses CANCEL_BY_SALON and CANCEL_BY_CUSTOMER should not trigger this function in practice.
		// However, TypeScript requires handling these cases (since the patch to '/api/b2b/admin/salons/{salonID}/calendar-events/reservations/{calendarEventID}/state' below does not accept these statuses), and this solution seems better than throwing a new Error() here.		if (state === RESERVATION_STATE.CANCEL_BY_SYSTEM || state === RESERVATION_STATE.CANCEL_BY_CUSTOMER) {
		if (state === RESERVATION_STATE.CANCEL_BY_SYSTEM || state === RESERVATION_STATE.CANCEL_BY_CUSTOMER) {
			showNotifications([
				{
					type: MSG_TYPE.ERROR,
					message: t('loc:Nie je možné upraviť rezerváciu v stave „{{ reservationState }}“', {
						reservationState: translateReservationState(state)
					})
				}
			])
			clearConfirmModal()
			return
		}

		// Validation when editing a reservation - for imported reservations, it’s possible that the required service field may be missing.
		const errors = validateReservationAndShowNotification({ serviceId: eventData?.serviceId, customerId: eventData?.customerId })

		if (!isEmpty(errors)) {
			clearConfirmModal()
			return
		}

		try {
			setIsSubmittingReservationState(true)
			await patchReq('/api/b2b/admin/salons/{salonID}/calendar-events/reservations/{calendarEventID}/state', {
				params: { path: { calendarEventID, salonID } },
				reqBody: {
					state,
					paymentMethod,
					reservationCancellation: quickFeedbackAnswers
						? {
								// TODO: Remove casting once type definitions are corrected
								text: (quickFeedbackAnswers === RESERVATION_FEEDBACK_STATE.OWN_MESSAGE && text ? text : undefined) as ReservationCancellation['text'],
								quickFeedbackAnswers: [quickFeedbackAnswers] as ReservationCancellation['quickFeedbackAnswers']
							}
						: undefined
				}
			})
			if (fetchEventDetailAfterStateUpdate) {
				const { calendarEvent, salon } = await getCalendarEventData(salonID, calendarEventID)
				if (calendarEvent && salon) {
					setReservationSidebarData((current) => ({ ...current, data: { calendarEvent, salon } }))
				}
			}
			fetchReservations()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			clearConfirmModal()
			setIsSubmittingReservationState(false)
		}
	}

	const initUpdateReservationStateData: HandleUpdateReservationStateFunc = (data) => {
		setConfirmModalData({ key: CONFIRM_MODAL_DATA_TYPE.UPDATE_RESERVATION_STATE, data, handleSubmit: handleUpdateReservationState })
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}` as ADMIN_RESERVATIONS_ORDER

			const newQuery = {
				...query,
				limit: pagination.pageSize,
				page: pagination.current,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === notinoReservations?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const onOpenInCalendar = (eventId: string, startDate: string, salonID: string) => {
		const redirectQuery: ICalendarPageURLQueryParams = {
			view: CALENDAR_VIEW.DAY,
			date: dayjs(startDate).format(CALENDAR_DATE_FORMAT.QUERY),
			sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
			eventId
		}

		navigate({
			pathname: t('paths:salons/{{salonID}}/calendar', { salonID }),
			search: formatObjToQuery(redirectQuery)
		})
	}

	const onEditEvent = (eventId: string, startDate: string, salonID: string) => {
		const redirectQuery: ICalendarPageURLQueryParams = {
			view: CALENDAR_VIEW.DAY,
			date: dayjs(startDate).format(CALENDAR_DATE_FORMAT.QUERY),
			sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
			eventId
		}

		navigate({
			pathname: t('paths:salons/{{salonID}}/calendar', { salonID }),
			search: formatObjToQuery(redirectQuery)
		})
	}

	const onRepeatReservation = (repeatReservationId: string, salonID: string) => {
		const redirectQuery: ICalendarPageURLQueryParams = {
			view: CALENDAR_VIEW.DAY,
			date: dayjs().format(CALENDAR_DATE_FORMAT.QUERY),
			sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
			repeatReservationId
		}

		navigate({
			pathname: t('paths:salons/{{salonID}}/calendar', { salonID }),
			search: formatObjToQuery(redirectQuery)
		})
	}

	const actionItems = (record: TableDataItem) => [
		!record.deletedAt &&
		!record.salon.deletedAt &&
		record.state !== RESERVATION_STATE.CANCEL_BY_SALON &&
		record.state !== RESERVATION_STATE.CANCEL_BY_SYSTEM &&
		record.state !== RESERVATION_STATE.CANCEL_BY_CUSTOMER &&
		record.state !== RESERVATION_STATE.DECLINED
			? {
					key: 'open_in_calendar',
					label: t('loc:Otvoriť v kalendári'),
					icon: <FullscreenIcon className={'small-icon'} />,
					onClick: () => {
						onOpenInCalendar(record.id, record.unformattedStartDate, record.salon.id)
					}
				}
			: null,
		!record.deletedAt && !record.salon.deletedAt
			? {
					key: 'edit',
					label: t('loc:Upraviť rezerváciu'),
					icon: <EditIcon className={'small-icon'} />,
					onClick: () => {
						onEditEvent(record.key, record.unformattedStartDate, record.salon.id)
					}
				}
			: null,
		record.state !== RESERVATION_STATE.PENDING
			? {
					key: 'duplicate',
					label: t('loc:Zopakovať rezerváciu'),
					icon: <RefreshIcon className={'small-icon'} />,
					onClick: () => {
						onRepeatReservation(record.key, record.salon.id)
					}
				}
			: null,
		// Show only if not deleted or declined
		record.state &&
		record.state !== RESERVATION_STATE.CANCEL_BY_SALON &&
		record.state !== RESERVATION_STATE.CANCEL_BY_SYSTEM &&
		record.state !== RESERVATION_STATE.CANCEL_BY_CUSTOMER &&
		record.state !== RESERVATION_STATE.DECLINED
			? {
					key: 'delete',
					label: t('loc:Zrušiť rezerváciu'),
					icon: <CloseIcon className={'small-icon'} />,
					className: 'text-notino-red',
					onClick: () => {
						initUpdateReservationStateData({
							calendarEventID: record.id,
							state: RESERVATION_STATE.CANCEL_BY_SALON,
							eventData: { serviceId: record.service.id, customerId: record.customer.id },
							salonID: record.salon.id
						})
					}
				}
			: null
	]

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:ID'),
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
			width: '8%',
			render: (_value, record) => {
				const firstThree = record.id.substring(0, 3)
				const lastThree = record.id.substring(record.id.length - 3)

				return <Tooltip title={record.id}>{`${firstThree}...${lastThree}`}</Tooltip>
			}
		},
		{
			title: t('loc:Názov salónu'),
			dataIndex: 'salon',
			key: 'salonName',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'salonName'),
			width: '12%',
			render: (_value, record) => {
				return record.salon.deletedAt ? (
					<Tooltip title={t('loc:Salón bol vymazaný')}>
						<div className={'text-notino-gray'}>{record.salon.name}</div>
					</Tooltip>
				) : (
					<div className={'truncate'}>{record.salon.name}</div>
				)
			}
		},
		{
			title: t('loc:Dátum vytvorenia'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'createdAt'),
			width: '12%'
		},
		{
			title: t('loc:Dátum rezervácie'),
			dataIndex: 'startDate',
			key: 'startDate',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'startDate'),
			width: '8%'
		},

		{
			title: t('loc:Trvanie'),
			dataIndex: 'time',
			key: 'time',
			ellipsis: true,
			width: '8%'
		},
		{
			title: t('loc:Zákazník'),
			dataIndex: 'customer',
			key: 'customer',
			ellipsis: true,
			width: '12%',
			render: (_value, record) => {
				return (
					<div title={record.customer.name} className={'flex items-center'}>
						<UserAvatar
							style={record.customer.deletedAt || record.deletedAt ? { filter: 'grayscale(100)', opacity: 0.75 } : undefined}
							className='mr-2-5 w-7 h-7'
							src={record.customer.thumbnail}
							fallBackSrc={record.customer.originalImage}
						/>
						{record.customer.deletedAt ? (
							<Tooltip title={t('loc:Priradený zákazník je vymazaný zo salónu')}>
								<div className={'text-notino-gray truncate'}>{record.customer.name}</div>
							</Tooltip>
						) : (
							<div className={'truncate'}>{record.customer.name}</div>
						)}
					</div>
				)
			}
		},
		{
			title: t('loc:Služba'),
			dataIndex: 'service',
			key: 'service',
			ellipsis: true,
			width: '12%',
			render: (_value, record) => {
				return (
					<div title={record.service.name} className={'flex items-center'}>
						<UserAvatar
							style={record.service.deletedAt || record.deletedAt ? { filter: 'grayscale(100)', opacity: 0.75 } : undefined}
							className='mr-2-5 w-7 h-7'
							src={record.service.thumbnail}
							fallBackSrc={record.service.originalImage}
						/>
						{record.service.deletedAt ? (
							<Tooltip title={t('loc:Priradená služba je vymazaná zo salónu')}>
								<div className={'text-notino-gray truncate'}>{record.service.name}</div>
							</Tooltip>
						) : (
							<div className={'truncate'}>{record.service.name}</div>
						)}
					</div>
				)
			}
		},
		{
			title: t('loc:Zamestnanec'),
			dataIndex: 'employee',
			key: 'employee',
			ellipsis: true,
			width: '12%',
			render: (_value, record) => {
				return (
					<div title={record.employee.name} className={'flex items-center'}>
						<UserAvatar
							style={record.employee.deletedAt || record.deletedAt ? { filter: 'grayscale(100)', opacity: 0.75 } : undefined}
							className='mr-2-5 w-7 h-7'
							src={record.employee.thumbnail}
							fallBackSrc={record.employee.originalImage}
						/>
						{record.employee.deletedAt ? (
							<Tooltip title={t('loc:Priradený kolega je vymazaný zo salónu')}>
								<div className={'text-notino-gray truncate'}>{record.employee.name}</div>
							</Tooltip>
						) : (
							<div className={'truncate'}>{record.employee.name}</div>
						)}
					</div>
				)
			}
		},
		{
			title: t('loc:Vytvoril'),
			dataIndex: 'createSourceType',
			key: 'createSourceType',
			ellipsis: true,
			width: '8%'
		},
		{
			title: t('loc:Stav'),
			dataIndex: 'state',
			key: 'state',
			ellipsis: true,
			width: '8%',
			render: (_value, record) => {
				if (!record.state) {
					return '-'
				}

				const { icon, text } = translateReservationState(record.state)

				return (
					<div title={text} className={'flex items-center'}>
						<div className={cx('mr-2 flex items-center w-4 h-4', { 'icon-grayslace': record.deletedAt, 'opacity-75': record.deletedAt })}>{icon}</div>
						<div className={'truncate'}>{text}</div>
					</div>
				)
			}
		},
		{
			title: t('loc:Spôsob úhrady'),
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			ellipsis: true,
			width: '160px',
			render: (_value, record) => {
				if (!record.paymentMethod) {
					return '-'
				}

				const { icon, text } = translateReservationPaymentMethod(record.paymentMethod)

				return (
					<div title={text} className={'flex items-center'}>
						<div className={cx('mr-2 flex items-center w-4 h-4', { 'icon-grayslace': record.deletedAt, 'opacity-75': record.deletedAt })}>{icon}</div>
						<div className={'truncate'}>{text}</div>
					</div>
				)
			}
		},
		{
			title: t('loc:Dátum vymazania'),
			dataIndex: 'deletedAt',
			key: 'deletedAt',
			ellipsis: true,
			width: '8%',
			render: (value) => value || '-'
		},
		{
			dataIndex: 'context-action',
			key: 'context-action',
			width: '50px',
			align: 'center',
			fixed: 'right',
			render: (_value, record) => {
				return (
					<div className={'flex-center '}>
						<Dropdown
							menu={{
								className: 'shadow-md flex flex-col gap-2',
								items: actionItems(record)
							}}
							placement='bottomRight'
							trigger={['click']}
						>
							<button aria-label='Manage reservation' className={'hover:bg-notino-grayLight p-1 rounded'} type={'button'} onClick={(e) => e.stopPropagation()}>
								<DotsIcon className={'w-4 h-4 rotate-90'} />
							</button>
						</Dropdown>
					</div>
				)
			}
		}
	]

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam rezervácií')
			}
		]
	}

	return (
		<>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<Row gutter={ROW_GUTTER_X_DEFAULT}>
				<Col span={24}>
					<Spin spinning={loading}>
						<div className='content-body'>
							<NotinoReservationsFilter onSubmit={handleSubmit} query={{ ...query, countryCode }} loading={loading} />
							<CustomTable<TableDataItem>
								className='table-fixed'
								columns={columns}
								onChange={onChangeTable}
								dataSource={notinoReservations?.tableData}
								rowClassName={(record) => cx({ 'noti-table-row-shadow': record.deletedAt, 'clickable-row': !record.deletedAt && !record.salon.deletedAt })}
								onRow={(record) => ({
									onClick: async () => {
										if (!record.deletedAt && !record.salon.deletedAt) {
											const { calendarEvent, salon } = await getCalendarEventData(record.salon.id, record.id)

											if (calendarEvent && salon) {
												setReservationSidebarData({ isOpen: true, data: { calendarEvent, salon } })
											}
										}
									}
								})}
								twoToneRows
								scroll={{ x: 1400 }}
								useCustomPagination
								pagination={{
									pageSize: notinoReservations.data?.pagination.limit,
									total: notinoReservations.data?.pagination.totalCount,
									current: notinoReservations.data?.pagination.page,
									onChange: onChangePagination,
									disabled: notinoReservations.isLoading
								}}
							/>
						</div>
					</Spin>
				</Col>
			</Row>
			{reservationSidebarData.data && (
				<ReservationDetailSidebar
					isOpen={reservationSidebarData.isOpen}
					setIsOpen={(isOpen) => {
						setReservationSidebarData((current) => ({ ...current, isOpen }))
					}}
					afterOpenChange={(open: boolean) => {
						if (!open) {
							setReservationSidebarData({ isOpen: false, data: null })
						}
					}}
					loading={loading}
					calendarEvent={reservationSidebarData.data.calendarEvent}
					color={reservationSidebarData.data.calendarEvent.color}
					salonData={reservationSidebarData.data.salon}
					handleUpdateReservationState={initUpdateReservationStateData}
					headerButtons={{
						onEditEvent: (eventData) => onEditEvent(eventData.id, eventData.start.date, reservationSidebarData.data?.salon.id as string),
						onOpenInCalendar: (eventData) => onOpenInCalendar(eventData.id, eventData.start.date, reservationSidebarData.data?.salon.id as string),
						onRepeatReservation: (eventId) => onRepeatReservation(eventId, reservationSidebarData.data?.salon.id as string)
					}}
				/>
			)}
			<CalendarConfirmModal data={confirmModalData} loadingData={loading} clearConfirmModal={clearConfirmModal} />
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO]))(NotinoReservationsPage)
