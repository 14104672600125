import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import BreadcrumbSeparator from '../assets/icons/breadcrumb-separator.svg?react'
import HomeIcon from '../assets/icons/home-icon-filled.svg?react'

type Props = {
	defaultHomeButtonAction?: any // Extra akcia ktora sa ma vykonat ku presmerovaniu (eg. premazat urcite data pri kliknuti spat atd...)
	showSeparator?: boolean
	className?: string
	homeButtonPath?: string
}

const HomeButton = ({ defaultHomeButtonAction, showSeparator = true, className, homeButtonPath }: Props) => {
	const [t] = useTranslation()

	return (
		<div className={cx('flex items-center', className)}>
			<Link className={'flex group'} onClick={defaultHomeButtonAction} to={homeButtonPath || t('paths:index')}>
				<HomeIcon className={'w-5 h-5 text-gray-600 group-hover:text-notino-black mr-1'} />
				<span className='text-gray-600 align-text-bottom text-base font-normal group-hover:text-notino-black'>{t('loc:Prehľad')}</span>
			</Link>
			{showSeparator && <BreadcrumbSeparator className={'text-gray-600'} />}
		</div>
	)
}

export default HomeButton
